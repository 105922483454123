import {
    GET_UOMS_REQUEST,
    GET_UOMS_SUCCESS,
    GET_UOMS_ERROR,
    ADD_UOM_STARTED,
    ADD_UOM_END,
    EDIT_UOM_STARTED,
    EDIT_UOM_END,
    DELETE_UOM_STARTED,
    DELETE_UOM_END,
    BULK_UPDATE_UOMS_STARTED,
    BULK_UPDATE_UOMS_END,
    SET_UOM_POPUP,
    CLEAR_UOM_LOADING,
    CLEAR_ALL_UOM
  } from '../actions/UomActions'
  
  const initialState = {
    bulkLoading: false,
    getUomsLoading: false,
    addUomLoading: false,
    editUomLoading: false,
    deleteUomLoading: false,
    uoms: [],
    uomPopup: false
  }
  
  function uom(state = initialState, action) {
    switch (action.type) {
      case GET_UOMS_REQUEST:
        return Object.assign({}, state, {
          getUomsLoading: true,
        })
      case GET_UOMS_SUCCESS:
        return Object.assign({}, state, {
          getUomsLoading: false,
          uoms: action.payload.uoms,
        })
      case GET_UOMS_ERROR:
        return Object.assign({}, state, {
          getUomsLoading: false,
          uoms: [],
        })
      case ADD_UOM_STARTED:
        return Object.assign({}, state, {
          addUomLoading: true,
        })
  
      case ADD_UOM_END:
        return Object.assign({}, state, {
          addUomLoading: false,
        })
  
      case EDIT_UOM_STARTED:
        return Object.assign({}, state, {
          editUomLoading: true,
        })
  
      case EDIT_UOM_END:
        return Object.assign({}, state, {
          editUomLoading: false,
        })
  
      case DELETE_UOM_STARTED:
        return Object.assign({}, state, {
          deleteUomLoading: true,
        })
  
      case DELETE_UOM_END:
        return Object.assign({}, state, {
          deleteUomLoading: false,
        })

      case BULK_UPDATE_UOMS_STARTED:
       return Object.assign({}, state, {
        bulkLoading: true
       })
  
      case BULK_UPDATE_UOMS_END:
        return Object.assign({}, state, {
          bulkLoading: false
       })
         
      case SET_UOM_POPUP:
        return Object.assign({}, state, {
          uomPopup: action.payload,        
        })

      case CLEAR_UOM_LOADING:
        return Object.assign({}, state, {
          getUomsLoading: false,
          addUomLoading: false,                
          editUomLoading: false,
          deleteUomLoading: false            
        })
      case CLEAR_ALL_UOM:
        return Object.assign({}, state, {
          getUomsLoading: false,            
          addUomLoading: false,                
          editUomLoading: false,
          deleteUomLoading: false,
          uoms: []            
        })
      default:
        return state
    }
  }
  
  export default uom
  