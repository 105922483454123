import {
    GET_STOCK_GROUPS_REQUEST,
    GET_STOCK_GROUPS_SUCCESS,
    GET_STOCK_GROUPS_ERROR,
    CLEAR_ALL_STOCK_GROUP,
    ADD_STOCK_GROUP_STARTED,
    ADD_STOCK_GROUP_END,
    EDIT_STOCK_GROUP_STARTED,
    EDIT_STOCK_GROUP_END,
    DELETE_STOCK_GROUP_STARTED,
    DELETE_STOCK_GROUP_END,
    BULK_UPDATE_STOCK_GROUPS_STARTED,
    BULK_UPDATE_STOCK_GROUPS_END,
    SET_STOCK_GROUP_POPUP,
    CLEAR_STOCK_GROUP_LOADING
  } from '../actions/StockGroupActions'
  
  const initialState = {
    loading: false,
    bulkLoading: false,
    stockGroups: [],
    stockGroupPopup: false,
    addLoading: false,
    editLoading: false,
    deleteLoading: false
  }
  
  function stockGroup(state = initialState, action) {
    switch (action.type) {
      case CLEAR_STOCK_GROUP_LOADING:
        return Object.assign({}, state, {
          loading: false,
          addLoading: false,
          editLoading: false,
          deleteLoading: false,
        })
      case GET_STOCK_GROUPS_REQUEST:
        return Object.assign({}, state, {
          loading: true
        })
      case GET_STOCK_GROUPS_SUCCESS:
        return Object.assign({}, state, {
          loading: false,
          stockGroups: action.payload.groups,
        })
      case GET_STOCK_GROUPS_ERROR:
        return Object.assign({}, state, {
          loading: false,
          stockGroups: [],
        })
  
      case ADD_STOCK_GROUP_STARTED:
        return Object.assign({}, state, {
          addLoading: true,
        })
  
      case ADD_STOCK_GROUP_END:
        return Object.assign({}, state, {
          addLoading: false,
        })
  
      case EDIT_STOCK_GROUP_STARTED:
        return Object.assign({}, state, {
          editLoading: true,
        })
  
      case EDIT_STOCK_GROUP_END:
        return Object.assign({}, state, {
          editLoading: false,
        })
  
      case DELETE_STOCK_GROUP_STARTED:
        return Object.assign({}, state, {
          deleteLoading: true,
        })
  
      case DELETE_STOCK_GROUP_END:
        return Object.assign({}, state, {
          deleteLoading: false,
        })

      case BULK_UPDATE_STOCK_GROUPS_END:
        return Object.assign({}, state, {
          bulkLoading: false
       })
         
      case BULK_UPDATE_STOCK_GROUPS_STARTED:
         return Object.assign({}, state, {
           bulkLoading: true
       })
  
      case SET_STOCK_GROUP_POPUP:
        return Object.assign({}, state, {
          stockGroupPopup: action.payload,
        })
  
      case CLEAR_ALL_STOCK_GROUP:
        return initialState
  
      default:
        return state
    }
  }
  
  export default stockGroup
  