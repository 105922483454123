import { getConversions, addConversion, editConversion, deleteConversion, bulkUpdateConversions } from '../../config/config'
import { toast } from 'react-toastify'
import { LOGOUT_USER, SET_PERMISSIONS } from './AuthActions'
import { handleError } from '../../utils/utils'
import { CLEAR_ALL_DISCOUNT } from './DiscountActions'

export const GET_CONVERSIONS_REQUEST = 'GET_CONVERSIONS_REQUEST'
export const GET_CONVERSIONS_SUCCESS = 'GET_CONVERSIONS_SUCCESS'
export const GET_CONVERSIONS_ERROR = 'GET_CONVERSIONS_ERROR'

export const ADD_CONVERSION_STARTED = 'ADD_CONVERSION_STARTED'
export const ADD_CONVERSION_END = 'ADD_CONVERSION_END'

export const EDIT_CONVERSION_STARTED = 'EDIT_CONVERSION_STARTED'
export const EDIT_CONVERSION_END = 'EDIT_CONVERSION_END'

export const DELETE_CONVERSION_STARTED = 'DELETE_CONVERSION_STARTED'
export const DELETE_CONVERSION_END = 'DELETE_CONVERSION_END'

export const BULK_UPDATE_CONVERSIONS_STARTED = 'BULK_UPDATE_CONVERSIONS_STARTED'
export const BULK_UPDATE_CONVERSIONS_END = 'BULK_UPDATE_CONVERSIONS_END'

export const SET_CONVERSION_POPUP = 'SET_CONVERSION_POPUP'

export const CLEAR_CONVERSION_LOADING = 'CLEAR_CONVERSION_LOADING'
export const CLEAR_ALL_CONVERSION = 'CLEAR_ALL_CONVERSION'

const actions = {
  getConversions: () => async (dispatch) => {
      dispatch({
        type: GET_CONVERSIONS_REQUEST,
      })
    await getConversions().then((res) => {
      if (res?.status === 200) {
        console.log(res?.data?.conversions)
        dispatch({
          type: GET_CONVERSIONS_SUCCESS,
          payload: {
            conversions: res?.data?.conversions,
          },
        })
      } else {
        handleError(dispatch)(res, null, 'GET CONVERSIONS ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get conversions error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
  },

  addConversion: (data) => async (dispatch) => {
    dispatch({
      type: ADD_CONVERSION_STARTED,
    })
    await addConversion(data).then((res) => {
      console.log(data)
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Added')
        dispatch({
          type: GET_CONVERSIONS_SUCCESS,
          payload: {
            conversions: res?.data?.conversions,  
          },
        })
        dispatch({
          type: SET_CONVERSION_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'ADD CONVERSION ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Add conversion error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: ADD_CONVERSION_END,
    })
  },

  editConversion: (uuid, data, setSelectedInfo) => async (dispatch) => {
    console.log(data)
    dispatch({
      type: EDIT_CONVERSION_STARTED,
    })
    await editConversion(uuid, data).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_CONVERSIONS_SUCCESS,
          payload: {
            conversions: res?.data?.conversions,
          },
        })
        dispatch({
          type: SET_CONVERSION_POPUP,
          payload: false,
        })
        setSelectedInfo({})
      } else {
        handleError(dispatch)(res, null, 'EDIT CONVERSION ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Edit conversion error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: EDIT_CONVERSION_END,
    })
  },

  deleteConversion: (uuid) => async (dispatch) => {
    dispatch({
      type: DELETE_CONVERSION_STARTED,
    })
    await deleteConversion(uuid).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Deleted')
        dispatch({
          type: GET_CONVERSIONS_SUCCESS,
          payload: {
            conversions: res?.data?.conversions,
          },
        })
        dispatch({
          type: SET_CONVERSION_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'DELETE CONVERSION ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Delete conversion Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: DELETE_CONVERSION_END,
    })
  },

  bulkUpdateConversions: (data, resetForm, setIsEditing) => async (dispatch) => {
        dispatch({
          type: BULK_UPDATE_CONVERSIONS_STARTED
        });
        let success = true
        let message = 'EDIT BULK CONVERSIONS SUCCESS'
        try {
          for (const item of data) {
            const response = await bulkUpdateConversions({ conversions: item });
    
            if (response.status === 200) {
              message = response?.data?.message || 'EDIT BULK CONVERSIONS SUCCESS'

              dispatch({
                type: GET_CONVERSIONS_SUCCESS,
                payload: {
                  conversions: response?.data?.conversions
                }
              });
            } else {
              success = false
              handleError(dispatch)(response, null, 'EDIT BULK ERROR');
            }
          }
          if (success) {
            toast.success(message)
            setIsEditing(false);
          } else resetForm()
        } catch (error) {
          console.error(error);
          toast.error('An unexpected error occurred during the bulk edit.');
        } finally {
          dispatch({
            type: BULK_UPDATE_CONVERSIONS_END
          });
        }
  },

  // SET ACTION MODAL STATE
  setActionModal: (state) => async (dispatch) => {
    dispatch({
      type: SET_CONVERSION_POPUP,
      payload: state,
    })
  },

  // CLEAR ALL Reducer
  clearAll: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_CONVERSION,
    })
  },
}

export default actions
