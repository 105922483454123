import {
    CLEAR_LAYOUT,
    SET_VIEW
  } from '../actions/StockLayoutActions'
  
  const initialState = {
    view: 'list'
  }
  
  const stockLayout = (state = initialState, action) => {
    switch (action.type) {
      case SET_VIEW:
        return Object.assign({}, state, {
          view: action.payload
        })
      case CLEAR_LAYOUT:
        return initialState
      default:
        return state
    }
  }
  
  export default stockLayout
  