import { getStockCategories, addStockCategory, editStockCategory, deleteStockCategory, bulkUpdateStockCategories } from '../../config/config'
import { toast } from 'react-toastify'
import { handleError } from '../../utils/utils'
import { parseError } from '../../utils/parseError'
import { LOGOUT_USER, SET_PERMISSIONS } from '../../redux/actions/AuthActions'

export const GET_STOCK_CATEGORIES_REQUEST = 'GET_STOCK_CATEGORIES_REQUEST'
export const GET_STOCK_CATEGORIES_SUCCESS = 'GET_STOCK_CATEGORIES_SUCCESS'
export const GET_STOCK_CATEGORIES_ERROR = 'GET_STOCK_CATEGORIES_ERROR'

export const ADD_STOCK_CATEGORY_STARTED = 'ADD_STOCK_CATEGORY_STARTED'
export const ADD_STOCK_CATEGORY_END = 'ADD_STOCK_CATEGORY_END'

export const EDIT_STOCK_CATEGORY_STARTED = 'EDIT_STOCK_CATEGORY_STARTED'
export const EDIT_STOCK_CATEGORY_END = 'EDIT_STOCK_CATEGORY_END'

export const DELETE_STOCK_CATEGORY_STARTED = 'DELETE_STOCK_CATEGORY_STARTED'
export const DELETE_STOCK_CATEGORY_END = 'DELETE_STOCK_CATEGORY_END'

export const BULK_UPDATE_STOCK_CATEGORIES_STARTED = 'BULK_UPDATE_STOCK_CATEGORIES_STARTED'
export const BULK_UPDATE_STOCK_CATEGORIES_END = 'BULK_UPDATE_STOCK_CATEGORIES_END'

export const IMPORT_STOCK_CATEGORIES_STARTED = 'IMPORT_STOCK_CATEGORIES_STARTED'
export const IMPORT_STOCK_CATEGORIES_ENDED = 'IMPORT_STOCK_CATEGORIES_ENDED'

export const SET_STOCK_CATEGORY_POPUP = 'SET_STOCK_CATEGORY_POPUP'
export const CLEAR_ALL_STOCK_CATEGORY = 'CLEAR_ALL_STOCK_CATEGORY'

export const CLEAR_STOCK_CATEGORY_LOADING = "CLEAR_STOCK_CATEGORY_LOADING"

const actions = {
  getStockCategories: (ruuid) => async (dispatch) => {
      dispatch({
        type: GET_STOCK_CATEGORIES_REQUEST
      })
    await getStockCategories(ruuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_STOCK_CATEGORIES_SUCCESS,
          payload: {
            categories: res?.data?.categories,
          },
        })
      } else {
        handleError(dispatch)(res, GET_STOCK_CATEGORIES_ERROR, 'GET STOCK CATEGORIES ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get All Stock Categories error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        dispatch({
          type: GET_STOCK_CATEGORIES_ERROR,
          payload: {
            message: parseError(res?.data?.error) || res?.data?.message || 'GET STOCK CATEGORIES error',
          },
        })
      }
    })
  },

  addStockCategory: (data) => async (dispatch) => {
    dispatch({
      type: ADD_STOCK_CATEGORY_STARTED,
    })
    await addStockCategory(data).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_STOCK_CATEGORIES_SUCCESS,
          payload: {
            categories: res?.data?.categories,
          },
        })
        dispatch({
          type: SET_STOCK_CATEGORY_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'ADD STOCK CATEGORY ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Add Stock Category Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: ADD_STOCK_CATEGORY_END,
    })
  },

  editStockCategory: (uuid, data, setSelectedInfo) => async (dispatch) => {
    dispatch({
      type: EDIT_STOCK_CATEGORY_STARTED,
    })
    await editStockCategory(uuid, data).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_STOCK_CATEGORIES_SUCCESS,
          payload: {
            categories: res?.data?.categories,
          },
        })
        dispatch({
          type: SET_STOCK_CATEGORY_POPUP,
          payload: false,
        })
        setSelectedInfo({})
      } else {
        handleError(dispatch)(res, null, 'EDIT STOCK CATEGORY ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Edit Category Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: EDIT_STOCK_CATEGORY_END,
    })
  },

  deleteStockCategory: (uuid) => async (dispatch) => {
    dispatch({
      type: DELETE_STOCK_CATEGORY_STARTED,
    })
    await deleteStockCategory(uuid).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Deleted')
        dispatch({
          type: GET_STOCK_CATEGORIES_SUCCESS,
          payload: {
            categories: res?.data?.categories,
          },
        })
        dispatch({
          type: SET_STOCK_CATEGORY_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'DELETE STOCK CATEGORY ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Delete Stock Category Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: DELETE_STOCK_CATEGORY_END,
    })
  },

  bulkUpdateStockCategories: (data, resetForm, setIsEditing) => async (dispatch) => {
        dispatch({
          type: BULK_UPDATE_STOCK_CATEGORIES_STARTED
        });
        let success = true
        let message = 'EDIT BULK STOCK CATEGORIES SUCCESS'
        try {
          for (const item of data) {
            const response = await bulkUpdateStockCategories({ categories: item });
    
            if (response.status === 200) {
              message = response?.data?.message || 'EDIT BULK STOCK CATEGORIES SUCCESS'
              //  toast.success(response?.data?.message || 'EDIT STOCK CATEGORIES SUCCESS');
              dispatch({
                type: GET_STOCK_CATEGORIES_SUCCESS,
                payload: {
                  categories: response?.data?.categories
                }
              });
            } else {
              success = false
              handleError(dispatch)(response, null, 'EDIT BULK ERROR');
            }
          }
          if (success) {
            toast.success(message)
            setIsEditing(false);
          } else resetForm()
        } catch (error) {
          console.error(error);
          toast.error('An unexpected error occurred during the bulk edit.');
        } finally {
          dispatch({
            type: BULK_UPDATE_STOCK_CATEGORIES_END
          });
        }

    // dispatch({
    //   type: BULK_UPDATE_STOCK_CATEGORIES_STARTED,
    // });
  
    // try {
    //   const response = await bulkUpdateStockCategories(data);
  
    //   if (response.status === 200) {
    //     toast.success(response.data.message || 'BULK UPDATE STOCK CATEGORIES SUCCESS');
    //     dispatch({
    //       type: BULK_UPDATE_STOCK_CATEGORIES_STARTED,
    //       payload: {
    //         categories: response?.data?.categories,
    //       },
    //     });
    //     setIsEditing(false);
    //   } else {
    //     resetForm();
    //     handleError(dispatch)(response, null, 'BULK UPDATE STOCK CATEGORIES ERROR');
    //   }
    // } catch (error) {
    //   resetForm();
    //   toast.error('An unexpected error occurred while updating stock categories');
    //   console.error('Bulk update stock categories error:', error);
    // } finally {
    //   dispatch({
    //     type: BULK_UPDATE_STOCK_CATEGORIES_END,
    //   });
    // }

  },
  
  setActionModal: (state) => async (dispatch) => {
    dispatch({
      type: SET_STOCK_CATEGORY_POPUP,
      payload: state,
    })
  },

  // CLEAR ALL Reducer
  clearAll: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_STOCK_CATEGORY,
    })
  },
}

export default actions
