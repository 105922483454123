import { getStockGroups, addStockGroup, editStockGroup, deleteStockGroup, bulkUpdateStockGroups } from '../../config/config'
import { toast } from 'react-toastify'
import { handleError } from '../../utils/utils'
import { parseError } from '../../utils/parseError'
import { LOGOUT_USER, SET_PERMISSIONS } from '../../redux/actions/AuthActions'

export const GET_STOCK_GROUPS_REQUEST = 'GET_STOCK_GROUPS_REQUEST'
export const GET_STOCK_GROUPS_SUCCESS = 'GET_STOCK_GROUPS_SUCCESS'
export const GET_STOCK_GROUPS_ERROR = 'GET_STOCK_GROUPS_ERROR'

export const ADD_STOCK_GROUP_STARTED = 'ADD_STOCK_GROUP_STARTED'
export const ADD_STOCK_GROUP_END = 'ADD_STOCK_GROUP_END'

export const EDIT_STOCK_GROUP_STARTED = 'EDIT_STOCK_GROUP_STARTED'
export const EDIT_STOCK_GROUP_END = 'EDIT_STOCK_GROUP_END'

export const DELETE_STOCK_GROUP_STARTED = 'DELETE_STOCK_GROUP_STARTED'
export const DELETE_STOCK_GROUP_END = 'DELETE_STOCK_GROUP_END'

export const BULK_UPDATE_STOCK_GROUPS_STARTED = 'BULK_UPDATE_STOCK_GROUPS_STARTED'
export const BULK_UPDATE_STOCK_GROUPS_END = 'BULK_UPDATE_STOCK_GROUPS_END'

export const IMPORT_STOCK_GROUPS_STARTED = 'IMPORT_STOCK_GROUPS_STARTED'
export const IMPORT_STOCK_GROUPS_ENDED = 'IMPORT_STOCK_GROUPS_ENDED'

export const SET_STOCK_GROUP_POPUP = 'SET_STOCK_GROUP_POPUP'
export const CLEAR_ALL_STOCK_GROUP = 'CLEAR_ALL_STOCK_GROUP'

export const CLEAR_STOCK_GROUP_LOADING = "CLEAR_STOCK_GROUP_LOADING"

const actions = {
  getStockGroups: (ruuid) => async (dispatch) => {
      dispatch({
        type: GET_STOCK_GROUPS_REQUEST
      })
    await getStockGroups(ruuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_STOCK_GROUPS_SUCCESS,
          payload: {
            groups: res?.data?.groups,
          },
        })
      } else {
        handleError(dispatch)(res, GET_STOCK_GROUPS_ERROR, 'GET STOCK GROUPS ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get All Stock Groups error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        dispatch({
          type: GET_STOCK_GROUPS_ERROR,
          payload: {
            message: parseError(res?.data?.error) || res?.data?.message || 'GET STOCK GROUPS error',
          },
        })
      }
    })
  },

  addStockGroup: (data) => async (dispatch) => {
    dispatch({
      type: ADD_STOCK_GROUP_STARTED,
    })
    await addStockGroup(data).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        console.log(res);
        dispatch({
          type: GET_STOCK_GROUPS_SUCCESS,
          payload: {
            groups: res?.data?.groups,
          },
        })
        dispatch({
          type: SET_STOCK_GROUP_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'ADD STOCK GROUP ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Add Stock Group Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: ADD_STOCK_GROUP_END,
    })
  },

  editStockGroup: (uuid, data, setSelectedInfo) => async (dispatch) => {
    dispatch({
      type: EDIT_STOCK_GROUP_STARTED,
    })
    await editStockGroup(uuid, data).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_STOCK_GROUPS_SUCCESS,
          payload: {
            groups: res?.data?.groups,
          },
        })
        dispatch({
          type: SET_STOCK_GROUP_POPUP,
          payload: false,
        })
        setSelectedInfo({})
      } else {
        handleError(dispatch)(res, null, 'EDIT STOCK GROUP ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Edit Group Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: EDIT_STOCK_GROUP_END,
    })
  },

  deleteStockGroup: (uuid) => async (dispatch) => {
    dispatch({
      type: DELETE_STOCK_GROUP_STARTED,
    })
    await deleteStockGroup(uuid).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Deleted')
        dispatch({
          type: GET_STOCK_GROUPS_SUCCESS,
          payload: {
            groups: res?.data?.groups,
          },
        })
        dispatch({
          type: SET_STOCK_GROUP_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'DELETE STOCK GROUP ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Delete Stock Group Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: DELETE_STOCK_GROUP_END,
    })
  },

   bulkUpdateStockGroups: (data, resetForm, setIsEditing) => async (dispatch) => {
        dispatch({
          type: BULK_UPDATE_STOCK_GROUPS_STARTED
        });
        let success = true
        let message = 'EDIT BULK STOCK GROUPS SUCCESS'
        try {
          for (const item of data) {
            const response = await bulkUpdateStockGroups({ groups: item });
    
            if (response.status === 200) {
              message = response?.data?.message || 'EDIT BULK STOCK GROUPS SUCCESS'
              dispatch({
                type: GET_STOCK_GROUPS_SUCCESS,
                payload: {
                  groups: response?.data?.groups
                }
              });
            } else {
              success = false
              handleError(dispatch)(response, null, 'EDIT BULK ERROR');
            }
          }
          if (success) {
            toast.success(message)
            setIsEditing(false);
          } else resetForm()
        } catch (error) {
          console.error(error);
          toast.error('An unexpected error occurred during the bulk edit.');
        } finally {
          dispatch({
            type: BULK_UPDATE_STOCK_GROUPS_END
          });
        }
  },
  setActionModal: (state) => async (dispatch) => {
    dispatch({
      type: SET_STOCK_GROUP_POPUP,
      payload: state,
    })
  },

  // CLEAR ALL Reducer
  clearAll: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_STOCK_GROUP,
    })
  },
}

export default actions
