import {
    GET_STOCK_CATEGORIES_REQUEST,
    GET_STOCK_CATEGORIES_SUCCESS,
    GET_STOCK_CATEGORIES_ERROR,
    CLEAR_ALL_STOCK_CATEGORY,
    ADD_STOCK_CATEGORY_STARTED,
    ADD_STOCK_CATEGORY_END,
    EDIT_STOCK_CATEGORY_STARTED,
    EDIT_STOCK_CATEGORY_END,
    DELETE_STOCK_CATEGORY_STARTED,
    DELETE_STOCK_CATEGORY_END,
    BULK_UPDATE_STOCK_CATEGORIES_STARTED,
    BULK_UPDATE_STOCK_CATEGORIES_END,
    SET_STOCK_CATEGORY_POPUP,
    CLEAR_STOCK_CATEGORY_LOADING
  } from '../actions/StockCategoryActions'
  
  const initialState = {
    loading: false,
    bulkLoading: false,
    stockCategories: [],
    stockCategoryPopup: false,
    addLoading: false,
    editLoading: false,
    deleteLoading: false
  }
  
  function stockCategory(state = initialState, action) {
    switch (action.type) {
      case CLEAR_STOCK_CATEGORY_LOADING:
        return Object.assign({}, state, {
          loading: false,
          addLoading: false,
          editLoading: false,
          deleteLoading: false,
        })
      case GET_STOCK_CATEGORIES_REQUEST:
        return Object.assign({}, state, {
          loading: true
        })
      case GET_STOCK_CATEGORIES_SUCCESS:
        return Object.assign({}, state, {
          loading: false,
          stockCategories: action.payload.categories,
        })
      case GET_STOCK_CATEGORIES_ERROR:
        return Object.assign({}, state, {
          loading: false,
          stockCategories: [],
        })
  
      case ADD_STOCK_CATEGORY_STARTED:
        return Object.assign({}, state, {
          addLoading: true,
        })
  
      case ADD_STOCK_CATEGORY_END:
        return Object.assign({}, state, {
          addLoading: false,
        })
  
      case EDIT_STOCK_CATEGORY_STARTED:
        return Object.assign({}, state, {
          editLoading: true,
        })
  
      case EDIT_STOCK_CATEGORY_END:
        return Object.assign({}, state, {
          editLoading: false,
        })
  
      case DELETE_STOCK_CATEGORY_STARTED:
        return Object.assign({}, state, {
          deleteLoading: true,
        })
  
      case DELETE_STOCK_CATEGORY_END:
        return Object.assign({}, state, {
          deleteLoading: false,
        })

      case BULK_UPDATE_STOCK_CATEGORIES_END:
        return Object.assign({}, state, {
          bulkLoading: false
       })
       
      case BULK_UPDATE_STOCK_CATEGORIES_STARTED:
        return Object.assign({}, state, {
          bulkLoading: true
       })

      case SET_STOCK_CATEGORY_POPUP:
        return Object.assign({}, state, {
          stockCategoryPopup: action.payload,
        })
  
      case CLEAR_ALL_STOCK_CATEGORY:
        return initialState
  
      default:
        return state
    }
  }
  
  export default stockCategory
  