import { getProducts, getProduct, addProduct, editProduct, deleteProduct, editProductImage, deleteProductImage, editBulkProducts, getBranchesFinanceProduct, updateBranchesFinancePrice, updateBranchesFinanceAvailability, getRewardsProducts, getAddEditProducts } from '../../config/config'

import { toast } from 'react-toastify'
import { LOGOUT_USER, SET_PERMISSIONS } from './AuthActions'
import { GET_MENU_DISPLAY_PRODUCTS_SUCCESS } from './MenuActions'
import { handleError } from '../../utils/utils'

export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST'
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS'
export const GET_PRODUCT_ERROR = 'GET_PRODUCT_ERROR'

export const GET_PRODUCTS_ADD_EDIT_REQUEST = 'GET_PRODUCTS_ADD_EDIT_REQUEST'
export const GET_PRODUCTS_ADD_EDIT_SUCCESS = 'GET_PRODUCTS_ADD_EDIT_SUCCESS'
export const GET_PRODUCTS_ADD_EDIT_ERROR = 'GET_PRODUCTS_ADD_EDIT_ERROR'

export const GET_PRODUCT_DETAILS_REQUEST = 'GET_PRODUCT_DETAILS_REQUEST'
export const GET_PRODUCT_DETAILS_SUCCESS = 'GET_PRODUCT_DETAILS_SUCCESS'
export const GET_PRODUCT_DETAILS_ERROR = 'GET_PRODUCT_DETAILS_ERROR'

export const GET_REWARDS_PRODUCTS_REQUEST = 'GET_REWARDS_PRODUCTS_REQUEST'
export const GET_REWARDS_PRODUCTS_SUCCESS = 'GET_REWARDS_PRODUCTS_SUCCESS'
export const GET_REWARDS_PRODUCTS_ERROR = 'GET_REWARDS_PRODUCTS_ERROR'

export const SET_ADD_PRODUCT_MODAL = 'SET_ADD_PRODUCT_MODAL'
export const SET_EDIT_PRODUCT_MODAL = 'SET_EDIT_PRODUCT_MODAL'

export const ADD_PRODUCT_STARTED = 'ADD_PRODUCT_STARTED'
export const ADD_PRODUCT_END = 'ADD_PRODUCT_END'

export const EDIT_PRODUCT_STARTED = 'EDIT_PRODUCT_STARTED'
export const EDIT_PRODUCT_END = 'EDIT_PRODUCT_END'

export const EDIT_BULK_PRODUCTS_STARTED = 'EDIT_BULK_PRODUCTS_STARTED'
export const EDIT_BULK_PRODUCTS_END = 'EDIT_BULK_PRODUCTS_END'

export const DELETE_PRODUCT_STARTED = 'DELETE_PRODUCT_STARTED'
export const DELETE_PRODUCT_END = 'DELETE_PRODUCT_END'

export const SET_SELECTED_PROD = 'SET_SELECTED_PROD'
export const CLEAR_ALL_PRODUCT = 'CLEAR_ALL_PRODUCT'

export const EDIT_PRODUCT_IMAGE_STARTED = 'EDIT_PRODUCT_IMAGE_STARTED'
export const EDIT_PRODUCT_IMAGE_END = 'EDIT_PRODUCT_IMAGE_END'

export const DELETE_PRODUCT_IMAGE_STARTED = 'DELETE_PRODUCT_IMAGE_STARTED'
export const DELETE_PRODUCT_IMAGE_END = 'DELETE_PRODUCT_IMAGE_END'

export const CLEAR_PRODUCTS_LOADING = 'CLEAR_PRODUCTS_LOADING'


const actions = {
  getAddEditProducts: (ruuid) => async (dispatch) => {
    dispatch({
      type: GET_PRODUCTS_ADD_EDIT_REQUEST
    })
    await getAddEditProducts(ruuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_PRODUCTS_ADD_EDIT_SUCCESS,
          payload: {
            products: res?.data?.products,
          },
        })
      } else {
        handleError(dispatch)(res, GET_PRODUCTS_ADD_EDIT_ERROR, 'GET ALL PRODUCTS ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get All Products error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_PRODUCT_ERROR,
        //   payload: {
        //     message: res?.data?.message || 'Get product error',
        //   },
        // })
      }
    })
  },
  getProductsReward: (ruuid) => async (dispatch) => {
    dispatch({
      type: GET_REWARDS_PRODUCTS_REQUEST
    })
    await getRewardsProducts(ruuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_REWARDS_PRODUCTS_SUCCESS,
          payload: {
            products: res?.data?.products,
          },
        })
      } else {
        handleError(dispatch)(res, GET_REWARDS_PRODUCTS_ERROR, 'GET ALL PRODUCTS ERROR')
      }
    })
  },
  getProducts: (uuid, forceLoading) => async (dispatch, getState) => {
    const currentRestaurant = getState().product.selectedRestaurant
    if (uuid !== currentRestaurant || forceLoading)
      dispatch({
        type: GET_PRODUCT_REQUEST,
        payload: uuid
      })
    await getProducts(uuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_PRODUCT_SUCCESS,
          payload: {
            products: res?.data?.products,
          },
        })
      } else {
        handleError(dispatch)(res, GET_PRODUCT_ERROR, 'GET ALL PRODUCTS ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get All Products error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_PRODUCT_ERROR,
        //   payload: {
        //     message: res?.data?.message || 'Get product error',
        //   },
        // })
      }
    })
  },

  getProduct: (ruuid, uuid) => async (dispatch) => {
    dispatch({
      type: GET_PRODUCT_DETAILS_REQUEST,
    })
    await getProduct(ruuid, uuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_PRODUCT_DETAILS_SUCCESS,
          payload: {
            product: res?.data?.product,
          },
        })
      } else {
        handleError(dispatch)(res, GET_PRODUCT_DETAILS_ERROR, 'GET PRODUCT DETAILS ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get Products details error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_PRODUCT_DETAILS_ERROR,
        //   payload: {
        //     message: res?.data?.message || 'Get product details error',
        //   },
        // })
      }
    })
  },

  addProduct: (ruuid, data, history, closable, bool, getMenusWithData, setSelectedMenu, parentFormik, selectedMenu, create, reset, resetAll, branchesFinancePricesNotSaved, branchesFinanceAvailabilityNotSaved) => async (dispatch, getState) => {
    let products = getState().product.products
    dispatch({
      type: ADD_PRODUCT_STARTED,
    })
    await addProduct(ruuid, data).then(async (res) => {
      if (res?.status === 200) {
        products.push(res.data.product)
        toast.success(res?.data?.message || 'Successfully Saved')
        let finances
        let finalPrices = []
        let finalAvailability = []
        await getBranchesFinanceProduct(ruuid, res?.data?.product.uuid).then((response) => {
          if (response.status === 200)
            finances = response.data.product.finances
        })
        if (branchesFinancePricesNotSaved.length > 0 && finances) {
          branchesFinancePricesNotSaved.map((branch) => {
            let target = finances.find((fin) => fin.branch?.uuid === branch.bruuid).prices.find((price) => price.key === branch.key)
            finalPrices.push({ uuid: target.uuid, price: branch.price })
          })
        }
        if (branchesFinanceAvailabilityNotSaved.length > 0 && finances) {
          branchesFinanceAvailabilityNotSaved.map((branch) => {
            let target = finances.find((fin) => fin.branch?.uuid === branch.bruuid).prices.find((price) => price.key === branch.key)
            finalAvailability.push(target.uuid)
          })
        }
        if (finalPrices.length > 0)
          await updateBranchesFinancePrice(ruuid, { prices: finalPrices })
        if (finalAvailability.length > 0)
          await updateBranchesFinanceAvailability(ruuid, { prices: finalAvailability })
        if (bool)
          dispatch({
            type: GET_MENU_DISPLAY_PRODUCTS_SUCCESS,
            payload: products
          })
        if (getMenusWithData && selectedMenu) {
          getMenusWithData(ruuid, setSelectedMenu, selectedMenu)
        }
        dispatch({
          type: GET_PRODUCT_SUCCESS,
          payload: {
            products: products,
          },
        })
        if (closable && !create)
          dispatch({
            type: SET_ADD_PRODUCT_MODAL,
            payload: false,
          })
        if (create) {
          reset()
          resetAll()
        }
        if (parentFormik) {
          let final = parentFormik.values
          let addedProduct = res.data.product //s.filter((resp) => !parentFormik.values.some((data) => data.uuid === resp.uuid))
          final.push(addedProduct)
          parentFormik.setValues(final)
        }
        // if (history) history.push('/product/product-attributes/list')
      } else {
        handleError(dispatch)(res, null, 'ADD PRODUCT ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Add Product error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: ADD_PRODUCT_END,
    })
  },

  editProduct: (ruuid, uuid, data, history, closable, bool, parentFormik, getMenusWithData, setSelectedMenu, selectedMenu, create, branchesFinancePrices, branchesFinanceAvailability) => async (dispatch, getState) => {
    let products = getState().product.products
    dispatch({
      type: EDIT_PRODUCT_STARTED,
    })
    await editProduct(data, ruuid, uuid).then(async (res) => {
      if (res?.status === 200) {
        let targetIndex = products.find((prod) => prod.uuid === res.data.product.uuid)
        if (targetIndex !== -1)
          products[targetIndex] = res.data.product
        toast.success(res?.data?.message || 'Successfully Saved')
        if (branchesFinancePrices.length > 0)
          await updateBranchesFinancePrice(ruuid, { prices: branchesFinancePrices })
        if (branchesFinanceAvailability.length > 0)
          await updateBranchesFinanceAvailability(ruuid, { prices: branchesFinanceAvailability })
        if (getMenusWithData)
          getMenusWithData(ruuid, setSelectedMenu, selectedMenu)
        if (bool)
          dispatch({
            type: GET_MENU_DISPLAY_PRODUCTS_SUCCESS,
            payload: products
          })
        dispatch({
          type: GET_PRODUCT_SUCCESS,
          payload: {
            products: products,
          },
        })
        if (closable)
          dispatch({
            type: SET_EDIT_PRODUCT_MODAL,
            payload: {
              open: create ? true : false,
              data: null,
            },
          })
        if (history) history.push('/product/product-attributes/list')
        if (parentFormik) {
          let final = []
          parentFormik.values.map((frm) => {
            if (frm.uuid === uuid) {
              let obj = res.data.product //s.find((data) => data.uuid === uuid)
              final.push(obj)
            } else {
              final.push(frm)
            }
          })
          parentFormik.setValues(final)
        }
      } else {
        handleError(dispatch)(res, null, 'EDIT PRODUCT ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Edit Product error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: EDIT_PRODUCT_END,
    })
  },

  deleteProduct: (uuid, history) => async (dispatch, getState) => {
    let products = getState().product.products
    dispatch({
      type: DELETE_PRODUCT_STARTED,
    })
    await deleteProduct(getState()?.rest?.selectedRest, uuid).then((res) => {
      if (res?.status === 200) {
        products = products.filter((prod) => prod.uuid !== uuid)
        toast.success(res?.data?.message || 'Successfully Deleted')
        dispatch({
          type: GET_PRODUCT_SUCCESS,
          payload: {
            products: products,
          },
        })
        // history.push('/product/product-attributes/list')
      } else {
        handleError(dispatch)(res, null, 'DELETE PRODUCT ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Delete Product error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: DELETE_PRODUCT_END,
    })
  },

  setProductAddModal: (value) => (dispatch) => {
    dispatch({
      type: SET_ADD_PRODUCT_MODAL,
      payload: value,
    })
  },

  setProductEditModal: (value) => (dispatch) => {
    dispatch({
      type: SET_EDIT_PRODUCT_MODAL,
      payload: value,
    })
  },
  // SET Selected Product
  setSelectedProd: (value) => (dispatch) => {
    dispatch({
      type: SET_SELECTED_PROD,
      payload: { selected: value },
    })
  },

  // CLEAR ALL Reducer
  clearAll: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_PRODUCT,
    })
  },

  editProductImage: (ruuid, uuid, data, isFromMenu) => async (dispatch) => {
    dispatch({
      type: EDIT_PRODUCT_IMAGE_STARTED,
    })
    await editProductImage(ruuid, uuid, data).then(async (res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        await getProducts(ruuid).then((res) => {
          if (res?.status === 200) {
            if (isFromMenu)
              dispatch({
                type: GET_MENU_DISPLAY_PRODUCTS_SUCCESS,
                payload: res?.data?.products
              })
            dispatch({
              type: GET_PRODUCT_SUCCESS,
              payload: {
                products: res?.data?.products,
              },
            })
            if (isFromMenu) {

            }
          } else {
            handleError(dispatch)(res, GET_PRODUCT_ERROR, 'GET PRODUCTS ERROR')
            // if (res.status !== 401 && res.status !== 403)
            //   toast.error(res?.data?.message || 'Get All Products error')
            // if (res.status === 401) {
            //   dispatch({ type: LOGOUT_USER })
            // }
            // if (res.status === 403)
            //   dispatch({
            //     type: SET_PERMISSIONS,
            //     payload: res?.data?.permissions
            //   })
            // dispatch({
            //   type: GET_PRODUCT_ERROR,
            //   payload: {
            //     message: res?.data?.message || 'Get product error',
            //   },
            // })
          }
        })
      } else {
        handleError(dispatch)(res, null, 'EDIT PRODUCT ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Edit Product error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: EDIT_PRODUCT_IMAGE_END,
    })
  },
  editBulkProducts: (ruuid, data, setIsEditing, setIsLinkedProductsView, reset) => async (dispatch) => {
    dispatch({
      type: EDIT_BULK_PRODUCTS_STARTED
    });
    let success = true
    let message = 'EDIT BULK PRODUCTS SUCCESS'
    try {
      for (const item of data) {
        const response = await editBulkProducts(ruuid, { products: item });

        if (response.status === 200) {
          message = response?.data?.message || 'EDIT BULK PRODUCTS SUCCESS'
          //  toast.success(response?.data?.message || 'EDIT BULK PRODUCTS SUCCESS');
          dispatch({
            type: GET_PRODUCT_SUCCESS,
            payload: {
              products: response?.data?.products
            }
          });
        } else {
          success = false
          handleError(dispatch)(response, null, 'EDIT BULK ERROR');
        }
      }
      if (success) {
        toast.success(message)
        setIsEditing(false);
        setIsLinkedProductsView(false);
      } else reset()
    } catch (error) {
      console.error(error);
      toast.error('An unexpected error occurred during the bulk edit.');
    } finally {
      dispatch({
        type: EDIT_BULK_PRODUCTS_END
      });
    }
  },

  deleteProductImage: (ruuid, uuid, isFromMenu) => async (dispatch) => {
    dispatch({
      type: DELETE_PRODUCT_IMAGE_STARTED,
    })
    await deleteProductImage(ruuid, uuid).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'DELETE PRODUCT IMAGE SUCCESS')
        if (isFromMenu)
          dispatch({
            type: GET_MENU_DISPLAY_PRODUCTS_SUCCESS,
            payload: res?.data?.products
          })
        dispatch({
          type: GET_PRODUCT_SUCCESS,
          payload: {
            products: res?.data?.products,
          },
        })
      } else {
        handleError(dispatch)(res, null, 'DELETE PRODUCT ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Delete Product error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: DELETE_PRODUCT_IMAGE_END,
    })
  },
}

export default actions
