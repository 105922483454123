import {
    GET_STOCK_SUPPLIERS_REQUEST,
    GET_STOCK_SUPPLIERS_SUCCESS,
    GET_STOCK_SUPPLIERS_ERROR,
    CLEAR_ALL_STOCK_SUPPLIER,
    ADD_STOCK_SUPPLIER_STARTED,
    ADD_STOCK_SUPPLIER_END,
    EDIT_STOCK_SUPPLIER_STARTED,
    EDIT_STOCK_SUPPLIER_END,
    DELETE_STOCK_SUPPLIER_STARTED,
    DELETE_STOCK_SUPPLIER_END,
    BULK_UPDATE_STOCK_SUPPLIERS_STARTED,
    BULK_UPDATE_STOCK_SUPPLIERS_END,
    SET_STOCK_SUPPLIER_POPUP,
    CLEAR_STOCK_SUPPLIER_LOADING
  } from '../actions/StockSupplierActions'
  
  const initialState = {
    loading: false,
    bulkLoading: false,
    stockSuppliers: [],
    stockSupplierPopup: false,
    addLoading: false,
    editLoading: false,
    deleteLoading: false
  }
  
  function stockSupplier(state = initialState, action) {
    switch (action.type) {
      case CLEAR_STOCK_SUPPLIER_LOADING:
        return Object.assign({}, state, {
          loading: false,
          addLoading: false,
          editLoading: false,
          deleteLoading: false,
        })
      case GET_STOCK_SUPPLIERS_REQUEST:
        return Object.assign({}, state, {
          loading: true
        })
      case GET_STOCK_SUPPLIERS_SUCCESS:
        return Object.assign({}, state, {
          loading: false,
          stockSuppliers: action.payload.suppliers,
        })
      case GET_STOCK_SUPPLIERS_ERROR:
        return Object.assign({}, state, {
          loading: false,
          stockSuppliers: [],
        })
  
      case ADD_STOCK_SUPPLIER_STARTED:
        return Object.assign({}, state, {
          addLoading: true,
        })
  
      case ADD_STOCK_SUPPLIER_END:
        return Object.assign({}, state, {
          addLoading: false,
        })
  
      case EDIT_STOCK_SUPPLIER_STARTED:
        return Object.assign({}, state, {
          editLoading: true,
        })
  
      case EDIT_STOCK_SUPPLIER_END:
        return Object.assign({}, state, {
          editLoading: false,
        })
  
      case DELETE_STOCK_SUPPLIER_STARTED:
        return Object.assign({}, state, {
          deleteLoading: true,
        })
  
      case DELETE_STOCK_SUPPLIER_END:
        return Object.assign({}, state, {
          deleteLoading: false,
        })

      case BULK_UPDATE_STOCK_SUPPLIERS_END:
        return Object.assign({}, state, {
          bulkLoading: false
       })
       
      case BULK_UPDATE_STOCK_SUPPLIERS_STARTED:
        return Object.assign({}, state, {
          bulkLoading: true
       })

      case SET_STOCK_SUPPLIER_POPUP:
        return Object.assign({}, state, {
          stockSupplierPopup: action.payload,
        })
  
      case CLEAR_ALL_STOCK_SUPPLIER:
        return initialState
  
      default:
        return state
    }
  }
  
  export default stockSupplier
  