import moment from 'moment'
import {
    getTopSpenders,
    getAvgOrderPrice,
    getLateOrders,
    getTotalOrders,
    getNewCustomers,
    getCanceledOrders,
    getSalesByZone,
    // getTotalCustomers,
    getSalesByAffiliate,
    getSignupsByAffiliate,
    getTotalDiscounts,
    getTotalPromoDiscounts,
    getTopXItemsQty,
    getTopXItemsAmt,
    getFeedbacksAndComplaints,
    getFeedbacksRating,
    getNotActiveCustomers,
    getTotalRevenuePerMonth,
    getNewProduct,
    getCustomerCountValue,
    getCustomerCountOrder,
    getQrCount,
    getNewProductsList,
    getFeedbacksSummary,
    getBirthdayCount,
    getDeliveryStatus,
    getNotSoldItems,
    getSalesByRestaurant,
    getSalesByBranch,
    getAffiliateClicks,
    getOrdersStatus,
    getTopItemsBranch,
    getAvgOrders,
    getSalesByCategory,
    getSalesByCustomer,
    getAvgPrepTime,
    getAvgDeliveryTime,
    getRestaurantsCurrency,
    setConsolidatedCurrency,
    getConsolidatedCurrency,
    getCardsPositioning,
    setCardsPositioning
} from '../../config/config'
import { handleError } from '../../utils/utils'
import { toast } from 'react-toastify'
export const SET_CARDS_POSITIONING_STARTED = 'SET_CARDS_POSITIONING_STARTED'
export const SET_CARDS_POSITIONING_ENDED = 'SET_CARDS_POSITIONING_ENDED'

export const GET_CARDS_POSITIONING_REQUEST = 'GET_CARDS_POSITIONING_REQUEST'
export const GET_CARDS_POSITIONING_SUCCESS = 'GET_CARDS_POSITIONING_SUCCESS'
export const GET_CARDS_POSITIONING_ERROR = 'GET_CARDS_POSITIONING_ERROR'

export const SET_PRODUCT_LAYOUT = 'SET_PRODUCT_LAYOUT'
export const SET_TODAY_LAYOUT = 'SET_TODAY_LAYOUT'
export const SET_TRANSACTIONS_LAYOUT = 'SET_TRANSACTIONS_LAYOUT'
export const SET_CUSTOMERS_LAYOUT = 'SET_CUSTOMERS_LAYOUT'

export const GET_RESTAURANTS_CURRENCY_REQUEST = 'GET_RESTAURANTS_CURRENCY_REQUEST'
export const GET_RESTAURANTS_CURRENCY_SUCCESS = 'GET_RESTAURANTS_CURRENCY_SUCCESS'
export const GET_RESTAURANTS_CURRENCY_ERROR = 'GET_RESTAURANTS_CURRENCY_ERROR'

export const GET_CONSOLIDATED_CURRENCY_REQUEST = 'GET_CONSOLIDATED_CURRENCY_REQUEST'
export const GET_CONSOLIDATED_CURRENCY_SUCCESS = 'GET_CONSOLIDATED_CURRENCY_SUCCESS'
export const GET_CONSOLIDATED_CURRENCY_ERROR = 'GET_CONSOLIDATED_CURRENCY_ERROR'

export const SET_CONSOLIDATED_CURRENCY_STARTED = 'SET_CONSOLIDATED_CURRENCY_STARTED'
export const SET_CONSOLIDATED_CURRENCY_ENDED = 'SET_CONSOLIDATED_CURRENCY_ENDED'

export const GET_DASHBOARD_TRANSACTION_REQUEST = 'GET_DASHBOARD_TRANSACTION_REQUEST'
export const GET_DASHBOARD_TRANSACTION_SUCCESS = 'GET_DASHBOARD_TRANSACTION_SUCCESS'
export const GET_DASHBOARD_TRANSACTION_ERROR = 'GET_DASHBOARD_TRANSACTION_ERROR'

export const GET_DASHBOARD_PRODUCTS_REQUEST = 'GET_DASHBOARD_PRODUCTS_REQUEST'
export const GET_DASHBOARD_PRODUCTS_SUCCESS = 'GET_DASHBOARD_PRODUCTS_SUCCESS'
export const GET_DASHBOARD_PRODUCTS_ERROR = 'GET_DASHBOARD_PRODUCTS_ERROR'

export const GET_DASHBOARD_CUSTOMERS_REQUEST = 'GET_DASHBOARD_CUSTOMERS_REQUEST'
export const GET_DASHBOARD_CUSTOMERS_SUCCESS = 'GET_DASHBOARD_CUSTOMERS_SUCCESS'
export const GET_DASHBOARD_CUSTOMERS_ERROR = 'GET_DASHBOARD_CUSTOMERS_ERROR'

export const GET_DASHBOARD_TOTAL_REVENUE_REQUEST = 'GET_DASHBOARD_TOTAL_REVENUE_REQUEST'
export const GET_DASHBOARD_TOTAL_REVENUE_SUCCESS = 'GET_DASHBOARD_TOTAL_REVENUE_SUCCESS'
export const GET_DASHBOARD_TOTAL_REVENUE_ERROR = 'GET_DASHBOARD_TOTAL_REVENUE_ERROR'

export const GET_DASHBOARD_ORDERS_VALUE_REQUEST = 'GET_DASHBOARD_ORDERS_VALUE_REQUEST'
export const GET_DASHBOARD_ORDERS_VALUE_SUCCESS = 'GET_DASHBOARD_ORDERS_VALUE_SUCCESS'
export const GET_DASHBOARD_ORDERS_VALUE_ERROR = 'GET_DASHBOARD_ORDERS_VALUE_ERROR'

export const GET_DASHBOARD_ORDERS_COUNT_REQUEST = 'GET_DASHBOARD_ORDERS_COUNT_REQUEST'
export const GET_DASHBOARD_ORDERS_COUNT_SUCCESS = 'GET_DASHBOARD_ORDERS_COUNT_SUCCESS'
export const GET_DASHBOARD_ORDERS_COUNT_ERROR = 'GET_DASHBOARD_ORDERS_COUNT_ERROR'

export const GET_DASHBOARD_NEW_PRODUCTS_REQUEST = 'GET_DASHBOARD_NEW_PRODUCTS_REQUEST'
export const GET_DASHBOARD_NEW_PRODUCTS_SUCCESS = 'GET_DASHBOARD_NEW_PRODUCTS_SUCCESS'
export const GET_DASHBOARD_NEW_PRODUCTS_ERROR = 'GET_DASHBOARD_NEW_PRODUCTS_ERROR'

export const GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_REQUEST = 'GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_REQUEST'
export const GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_SUCCESS = 'GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_SUCCESS'
export const GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_ERROR = 'GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_ERROR'

export const SET_DASHBOARD_REST = 'SET_DASHBOARD_REST'

export const CLEAR_DASHBOARD_LOADING = 'CLEAR_DASHBOARD_LOADING'

export const GET_TOP_SPENDERS_REQUEST = 'GET_TOP_SPENDERS_REQUEST'
export const GET_TOP_SPENDERS_SUCCESS = 'GET_TOP_SPENDERS_SUCCESS'
export const GET_TOP_SPENDERS_ERROR = 'GET_TOP_SPENDERS_ERROR'

export const GET_AVERAGE_ORDER_PRICE_REQUEST = 'GET_AVERAGE_ORDER_PRICE_REQUEST'
export const GET_AVERAGE_ORDER_PRICE_SUCCESS = 'GET_AVERAGE_ORDER_PRICE_SUCCESS'
export const GET_AVERAGE_ORDER_PRICE_ERROR = 'GET_AVERAGE_ORDER_PRICE_ERROR'

export const GET_AVERAGE_ORDER_PRICE_PERIOD_REQUEST = 'GET_AVERAGE_ORDER_PRICE_PERIOD_REQUEST'
export const GET_AVERAGE_ORDER_PRICE_PERIOD_SUCCESS = 'GET_AVERAGE_ORDER_PRICE_PERIOD_SUCCESS'
export const GET_AVERAGE_ORDER_PRICE_PERIOD_ERROR = 'GET_AVERAGE_ORDER_PRICE_PERIOD_ERROR'

export const GET_LATE_ORDERS_REQUEST = 'GET_LATE_ORDERS_REQUEST'
export const GET_LATE_ORDERS_SUCCESS = 'GET_LATE_ORDERS_SUCCESS'
export const GET_LATE_ORDERS_ERROR = 'GET_LATE_ORDERS_ERROR'

export const GET_TOTAL_ORDERS_REQUEST = 'GET_TOTAL_ORDERS_REQUEST'
export const GET_TOTAL_ORDERS_SUCCESS = 'GET_TOTAL_ORDERS_SUCCESS'
export const GET_TOTAL_ORDERS_ERROR = 'GET_TOTAL_ORDERS_ERROR'

export const GET_NEW_CUSTOMERS_REQUEST = 'GET_NEW_CUSTOMERS_REQUEST'
export const GET_NEW_CUSTOMERS_SUCCESS = 'GET_NEW_CUSTOMERS_SUCCESS'
export const GET_NEW_CUSTOMERS_ERROR = 'GET_NEW_CUSTOMERS_ERROR'

export const GET_CANCELED_ORDERS_REQUEST = 'GET_CANCELED_ORDERS_REQUEST'
export const GET_CANCELED_ORDERS_SUCCESS = 'GET_CANCELED_ORDERS_SUCCESS'
export const GET_CANCELED_ORDERS_ERROR = 'GET_CANCELED_ORDERS_ERROR'

export const GET_SALES_BY_ZONE_REQUEST = 'GET_SALES_BY_ZONE_REQUEST'
export const GET_SALES_BY_ZONE_SUCCESS = 'GET_SALES_BY_ZONE_SUCCESS'
export const GET_SALES_BY_ZONE_ERROR = 'GET_SALES_BY_ZONE_ERROR'

export const GET_SALES_BY_AFFILIATE_REQUEST = 'GET_SALES_BY_AFFILIATE_REQUEST'
export const GET_SALES_BY_AFFILIATE_SUCCESS = 'GET_SALES_BY_AFFILIATE_SUCCESS'
export const GET_SALES_BY_AFFILIATE_ERROR = 'GET_SALES_BY_AFFILIATE_ERROR'

export const GET_SIGNUPS_BY_AFFILIATE_REQUEST = 'GET_SIGNUPS_BY_AFFILIATE_REQUEST'
export const GET_SIGNUPS_BY_AFFILIATE_SUCCESS = 'GET_SIGNUPS_BY_AFFILIATE_SUCCESS'
export const GET_SIGNUPS_BY_AFFILIATE_ERROR = 'GET_SIGNUPS_BY_AFFILIATE_ERROR'

export const GET_TOTAL_DISCOUNTS_REQUEST = 'GET_TOTAL_DISCOUNTS_REQUEST'
export const GET_TOTAL_DISCOUNTS_SUCCESS = 'GET_TOTAL_DISCOUNTS_SUCCESS'
export const GET_TOTAL_DISCOUNTS_ERROR = 'GET_TOTAL_DISCOUNTS_ERROR'

export const GET_TOTAL_PROMO_REQUEST = 'GET_TOTAL_PROMO_REQUEST'
export const GET_TOTAL_PROMO_SUCCESS = 'GET_TOTAL_PROMO_SUCCESS'
export const GET_TOTAL_PROMO_ERROR = 'GET_TOTAL_PROMO_ERROR'

export const GET_TOP_X_ITEMS_QTY_REQUEST = 'GET_TOP_X_ITEMS_QTY_REQUEST'
export const GET_TOP_X_ITEMS_QTY_SUCCESS = 'GET_TOP_X_ITEMS_QTY_SUCCESS'
export const GET_TOP_X_ITEMS_QTY_ERROR = 'GET_TOP_X_ITEMS_QTY_ERROR'

export const GET_LEAST_X_ITEMS_QTY_REQUEST = 'GET_LEAST_X_ITEMS_QTY_REQUEST'
export const GET_LEAST_X_ITEMS_QTY_SUCCESS = 'GET_LEAST_X_ITEMS_QTY_SUCCESS'
export const GET_LEAST_X_ITEMS_QTY_ERROR = 'GET_LEAST_X_ITEMS_QTY_ERROR'

export const GET_TOP_X_ITEMS_AMT_REQUEST = 'GET_TOP_X_ITEMS_AMT_REQUEST'
export const GET_TOP_X_ITEMS_AMT_SUCCESS = 'GET_TOP_X_ITEMS_AMT_SUCCESS'
export const GET_TOP_X_ITEMS_AMT_ERROR = 'GET_TOP_X_ITEMS_AMT_ERROR'

export const GET_LEAST_X_ITEMS_AMT_REQUEST = 'GET_LEAST_X_ITEMS_AMT_REQUEST'
export const GET_LEAST_X_ITEMS_AMT_SUCCESS = 'GET_LEAST_X_ITEMS_AMT_SUCCESS'
export const GET_LEAST_X_ITEMS_AMT_ERROR = 'GET_LEAST_X_ITEMS_AMT_ERROR'

export const GET_FEEDBACKS_COMPLAINTS_REQUEST = 'GET_FEEDBACKS_COMPLAINTS_REQUEST'
export const GET_FEEDBACKS_COMPLAINTS_SUCCESS = 'GET_FEEDBACKS_COMPLAINTS_SUCCESS'
export const GET_FEEDBACKS_COMPLAINTS_ERROR = 'GET_FEEDBACKS_COMPLAINTS_ERROR'

export const GET_FEEDBACKS_RATING_REQUEST = 'GET_FEEDBACKS_RATING_REQUEST'
export const GET_FEEDBACKS_RATING_SUCCESS = 'GET_FEEDBACKS_RATING_SUCCESS'
export const GET_FEEDBACKS_RATING_ERROR = 'GET_FEEDBACKS_RATING_ERROR'

export const GET_NOT_ACTIVE_CUSTOMERS_REQUEST = 'GET_NOT_ACTIVE_CUSTOMERS_REQUEST'
export const GET_NOT_ACTIVE_CUSTOMERS_SUCCESS = 'GET_NOT_ACTIVE_CUSTOMERS_SUCCESS'
export const GET_NOT_ACTIVE_CUSTOMERS_ERROR = 'GET_NOT_ACTIVE_CUSTOMERS_ERROR'

export const GET_TOTAL_REVENUE_MONTH_REQUEST = 'GET_TOTAL_REVENUE_MONTH_REQUEST'
export const GET_TOTAL_REVENUE_MONTH_SUCCESS = 'GET_TOTAL_REVENUE_MONTH_SUCCESS'
export const GET_TOTAL_REVENUE_MONTH_ERROR = 'GET_TOTAL_REVENUE_MONTH_ERROR'

export const GET_NEW_PRODUCT_REQUEST = 'GET_NEW_PRODUCT_REQUEST'
export const GET_NEW_PRODUCT_SUCCESS = 'GET_NEW_PRODUCT_SUCCESS'
export const GET_NEW_PRODUCT_ERROR = 'GET_NEW_PRODUCT_ERROR'

export const GET_CUSTOMER_COUNT_VALUE_REQUEST = 'GET_CUSTOMER_COUNT_VALUE_REQUEST'
export const GET_CUSTOMER_COUNT_VALUE_SUCCESS = 'GET_CUSTOMER_COUNT_VALUE_SUCCESS'
export const GET_CUSTOMER_COUNT_VALUE_ERROR = 'GET_CUSTOMER_COUNT_VALUE_ERROR'

export const GET_CUSTOMERS_COUNT_REQUEST = 'GET_CUSTOMERS_COUNT_REQUEST'
export const GET_CUSTOMERS_COUNT_SUCCESS = 'GET_CUSTOMERS_COUNT_SUCCESS'
export const GET_CUSTOMERS_COUNT_ERROR = 'GET_CUSTOMERS_COUNT_ERROR'

export const GET_TOTAL_ORDERS_YEAR_REQUEST = 'GET_TOTAL_ORDERS_YEAR_REQUEST'
export const GET_TOTAL_ORDERS_YEAR_SUCCESS = 'GET_TOTAL_ORDERS_YEAR_SUCCESS'
export const GET_TOTAL_ORDERS_YEAR_ERROR = 'GET_TOTAL_ORDERS_YEAR_ERROR'

export const GET_QR_COUNT_REQUEST = 'GET_QR_COUNT_REQUEST'
export const GET_QR_COUNT_SUCCESS = 'GET_QR_COUNT_SUCCESS'
export const GET_QR_COUNT_ERROR = 'GET_QR_COUNT_ERROR'

export const GET_FEEDBACKS_SUMMARY_POSITIVE_REQUEST = 'GET_FEEDBACKS_SUMMARY_POSITIVE_REQUEST'
export const GET_FEEDBACKS_SUMMARY_POSITIVE_SUCCESS = 'GET_FEEDBACKS_SUMMARY_POSITIVE_SUCCESS'
export const GET_FEEDBACKS_SUMMARY_POSITIVE_ERROR = 'GET_FEEDBACKS_SUMMARY_POSITIVE_ERROR'

export const GET_FEEDBACKS_SUMMARY_NEGATIVE_REQUEST = 'GET_FEEDBACKS_SUMMARY_NEGATIVE_REQUEST'
export const GET_FEEDBACKS_SUMMARY_NEGATIVE_SUCCESS = 'GET_FEEDBACKS_SUMMARY_NEGATIVE_SUCCESS'
export const GET_FEEDBACKS_SUMMARY_NEGATIVE_ERROR = 'GET_FEEDBACKS_SUMMARY_NEGATIVE_ERROR'

export const GET_BIRTHDAY_COUNT_REQUEST = 'GET_BIRTHDAY_COUNT_REQUEST'
export const GET_BIRTHDAY_COUNT_SUCCESS = 'GET_BIRTHDAY_COUNT_SUCCESS'
export const GET_BIRTHDAY_COUNT_ERROR = 'GET_BIRTHDAY_COUNT_ERROR'

export const GET_DELIVERIES_STATUS_REQUEST = 'GET_DELIVERIES_STATUS_REQUEST'
export const GET_DELIVERIES_STATUS_SUCCESS = 'GET_DELIVERIES_STATUS_SUCCESS'
export const GET_DELIVERIES_STATUS_ERROR = 'GET_DELIVERIES_STATUS_ERROR'

export const GET_NOT_SOLD_ITEMS_REQUEST = 'GET_NOT_SOLD_ITEMS_REQUEST'
export const GET_NOT_SOLD_ITEMS_SUCCESS = 'GET_NOT_SOLD_ITEMS_SUCCESS'
export const GET_NOT_SOLD_ITEMS_ERROR = 'GET_NOT_SOLD_ITEMS_ERROR'

export const GET_ORDERS_STATUS_REQUEST = 'GET_ORDERS_STATUS_REQUEST'
export const GET_ORDERS_STATUS_SUCCESS = 'GET_ORDERS_STATUS_SUCCESS'
export const GET_ORDERS_STATUS_ERROR = 'GET_ORDERS_STATUS_ERROR'

export const GET_AVERAGE_DELIVERY_TIME_REQUEST = 'GET_AVERAGE_DELIVERY_TIME_REQUEST'
export const GET_AVERAGE_DELIVERY_TIME_SUCCESS = 'GET_AVERAGE_DELIVERY_TIME_SUCCESS'
export const GET_AVERAGE_DELIVERY_TIME_ERROR = 'GET_AVERAGE_DELIVERY_TIME_ERROR'

export const GET_AVERAGE_PREPARATION_TIME_REQUEST = 'GET_AVERAGE_PREPARATION_TIME_REQUEST'
export const GET_AVERAGE_PREPARATION_TIME_SUCCESS = 'GET_AVERAGE_PREPARATION_TIME_SUCCESS'
export const GET_AVERAGE_PREPARATION_TIME_ERROR = 'GET_AVERAGE_PREPARATION_TIME_ERROR'

export const GET_TOP_ITEMS_BRANCH_REQUEST = 'GET_TOP_ITEMS_BRANCH_REQUEST'
export const GET_TOP_ITEMS_BRANCH_SUCCESS = 'GET_TOP_ITEMS_BRANCH_SUCCESS'
export const GET_TOP_ITEMS_BRANCH_ERROR = 'GET_TOP_ITEMS_BRANCH_ERROR'

export const GET_SALES_BY_RESTAURANT_REQUEST = 'GET_SALES_BY_RESTAURANT_REQUEST'
export const GET_SALES_BY_RESTAURANT_SUCCESS = 'GET_SALES_BY_RESTAURANT_SUCCESS'
export const GET_SALES_BY_RESTAURANT_ERROR = 'GET_SALES_BY_RESTAURANT_ERROR'

export const GET_SALES_BY_BRANCH_REQUEST = 'GET_SALES_BY_BRANCH_REQUEST'
export const GET_SALES_BY_BRANCH_SUCCESS = 'GET_SALES_BY_BRANCH_SUCCESS'
export const GET_SALES_BY_BRANCH_ERROR = 'GET_SALES_BY_BRANCH_ERROR'

export const GET_AFFILIATE_CLICKS_REQUEST = 'GET_AFFILIATE_CLICKS_REQUEST'
export const GET_AFFILIATE_CLICKS_SUCCESS = 'GET_AFFILIATE_CLICKS_SUCCESS'
export const GET_AFFILIATE_CLICKS_ERROR = 'GET_AFFILIATE_CLICKS_ERROR'

export const GET_AVERAGE_ORDERS_REQUEST = 'GET_AVERAGE_ORDERS_REQUEST'
export const GET_AVERAGE_ORDERS_SUCCESS = 'GET_AVERAGE_ORDERS_SUCCESS'
export const GET_AVERAGE_ORDERS_ERROR = 'GET_AVERAGE_ORDERS_ERROR'

export const GET_AVERAGE_ORDERS_YEAR_REQUEST = 'GET_AVERAGE_ORDERS_YEAR_REQUEST'
export const GET_AVERAGE_ORDERS_YEAR_SUCCESS = 'GET_AVERAGE_ORDERS_YEAR_SUCCESS'
export const GET_AVERAGE_ORDERS_YEAR_ERROR = 'GET_AVERAGE_ORDERS_YEAR_ERROR'

export const GET_SALES_BY_CATEGORY_REQUEST = 'GET_SALES_BY_CATEGORY_REQUEST'
export const GET_SALES_BY_CATEGORY_SUCCESS = 'GET_SALES_BY_CATEGORY_SUCCESS'
export const GET_SALES_BY_CATEGORY_ERROR = 'GET_SALES_BY_CATEGORY_ERROR'

export const GET_SALES_BY_CUSTOMER_REQUEST = 'GET_SALES_BY_CUSTOMER_REQUEST'
export const GET_SALES_BY_CUSTOMER_SUCCESS = 'GET_SALES_BY_CUSTOMER_SUCCESS'
export const GET_SALES_BY_CUSTOMER_ERROR = 'GET_SALES_BY_CUSTOMER_ERROR'

const keys = {
    notSoldItems: [
        'fromDate',
        'toDate',
        'key',
        'buuid'
    ],
    deliveryStatus: [
        'fromDate',
        'toDate',
        'key',
        'buuid'
    ],
    topSpenders: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    avgOrderPrice: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    lateOrders: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    totalOrders: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    newCustomers: [
        'fromDate',
        'toDate',
        'key'
    ],
    canceledOrders: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    salesByZone: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    salesByAffiliate: [],
    signupsByAffiliate: [],
    totalDiscounts: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    totalPromoDiscounts: [
        'fromDate',
        'toDate',
        'buuid'
    ],
    topXItems: [
        'fromDate',
        'toDate',
        'buuid',
        'limit',
        'key',
        'order'
    ],
    topXItemsAmt: [
        'fromDate',
        'toDate',
        'buuid',
        'limit',
        'key',
        'order'
    ],
    notActiveCustomers: [
        'fromDate',
        'toDate',
        'key',
        'buuid'
    ],
    feedbacksAndComplaints: [
        'fromDate',
        'toDate',
        'key',
        'limit',
        'buuid'
    ],
    feedbacksRating: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    totalRevenueMonth: [
        'key',
        'years',
        'buuid'
    ],
    newProducts: [
        'fromDate',
        'toDate'
    ],
    customerCountValue: [
        'fromDate',
        'toDate',
        'key',
        'amount',
        'buuid'
    ],
    customerCountOrder: [
        'fromDate',
        'toDate',
        'key',
        'count',
        'buuid'
    ],
    qrScans: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    newProductsList: [
        'fromDate',
        'toDate',
        'buuid'
    ],
    feedbacksSummary: [
        'fromDate',
        'toDate',
        'limit',
        'key',
        'buuid'
    ],
    birthdayCount: [
        'fromDate',
        'toDate'
    ],
    salesByRestaurant: [
        'fromDate',
        'toDate'
    ],
    salesByBranch: [
        'fromDate',
        'toDate'
    ],
    ordersStatus: [
        'fromDate',
        'toDate',
        'buuid'
    ],
    topItemsBranch: [
        'fromDate',
        'toDate',
        'limit',
        'buuid'
    ],
    avgOrders: [
        'fromDate',
        'toDate',
        'buuid'
    ],
    salesByCategory: [
        'fromDate',
        'toDate'
    ],
    salesByCustomer: [
        'fromDate',
        'toDate'
    ],
    avgPrepTime: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    avgDelvTime: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ]
}

const getFilterString = (
    queryKeys,
    selectedBranches,
    filter,
    activeSection,
    topItemsLimit,
    topItemsAmtLimit,
    feedbacksAndComplaintsLimit,
    feedbacksSummaryLimit,
    years,
    amount,
    count,
    thisYear,
    forcedKey,
    order,
    topItemsBranchLimit
) => {
    let targetQueries = keys[queryKeys];
    if (!targetQueries) return '';

    let filterString = '?';
    let queryParts = [];

    targetQueries.forEach((key) => {
        switch (key) {
            case 'fromDate':
                if (activeSection !== 'today')
                    queryParts.push(
                        !thisYear
                            ? `fromDate=${filter.period.start.format('YYYY-MM-DD')}`
                            : `fromDate=${moment().startOf('year').format('YYYY-MM-DD')}`
                    );
                break;

            case 'toDate':
                if (activeSection !== 'today')
                    queryParts.push(
                        !thisYear
                            ? `toDate=${filter.period.end.format('YYYY-MM-DD')}`
                            : `toDate=${moment().endOf('year').format('YYYY-MM-DD')}`
                    );
                break;

            case 'buuid':
                if (selectedBranches.length > 0) {
                    selectedBranches.forEach((br) => {
                        queryParts.push(`buuid=${br}`);
                    });
                }
                break;

            case 'key':
                if (forcedKey)
                    queryParts.push(`key=${forcedKey}`);
                else
                    queryParts.push(
                        `key=${activeSection === 'today' ? 'not-closed' : 'closed'}`
                    );

                break;

            case 'limit':
                if (queryKeys === 'topXItems' && topItemsLimit) {
                    queryParts.push(`limit=${topItemsLimit}`);
                }
                if (queryKeys === 'topXItemsAmt' && topItemsAmtLimit) {
                    queryParts.push(`limit=${topItemsAmtLimit}`);
                }
                if (queryKeys === 'feedbacksAndComplaints' && feedbacksAndComplaintsLimit) {
                    queryParts.push(`limit=${feedbacksAndComplaintsLimit}`);
                }
                if (queryKeys === 'feedbacksSummary' && feedbacksSummaryLimit) {
                    queryParts.push(`limit=${feedbacksSummaryLimit}`);
                }
                if (queryKeys === 'topItemsBranch' && topItemsBranchLimit) {
                    queryParts.push(`limit=${topItemsBranchLimit}`);
                }
                break;

            case 'order':
                if (order) queryParts.push(`order=${order}`);
                break;

            case 'years':
                if (years?.length > 0) {
                    years.forEach((year) => {
                        queryParts.push(`years=${year}`);
                    });
                }
                break;

            case 'amount':
                queryParts.push(`amount=${amount}`);
                break;

            case 'count':
                queryParts.push(`count=${count}`);
                break;

            default:
                break;
        }
    });

    filterString += queryParts.join('&');
    return filterString;
};

function getMinMax(card, key, defaultObj) {
    let final = defaultObj[key]?.find((card2) => card2.i === card.i)
    return {
        minW: final.minW,
        minH: final.minH,
        maxW: final.maxW,
        maxH: final.maxH
    }
}

function checkArrays(obj, defaultObj) {
    const defaultCards = defaultObj.lg.map((card) => card.i)
    // Check if any of the arrays inside the object is empty
    const hasEmptyArray = Object.values(obj).some(arr => Array.isArray(arr) && arr.length === 0);

    // If any array is empty, return null
    if (hasEmptyArray) {
        // return defaultObj;
        return Object.fromEntries(
            Object.keys(defaultObj).map((key) => [
                key,
                // obj[key].map((card) => {
                //     let tmp = getMinMax(card, key, defaultObj);
                //     return { ...card, ...tmp };
                // })
                defaultObj[key].map((dfCard) => {
                    return{...dfCard, isDraggable: false, isResizable: false}
                })
            ])
        ); 
    }
    let final = Object.fromEntries(
        Object.keys(obj).map((key) => [
            key,
            // obj[key].map((card) => {
            //     let tmp = getMinMax(card, key, defaultObj);
            //     return { ...card, ...tmp };
            // })
            defaultCards.map((dfCard) => {
                let target = obj[key].find((card) => card.i === dfCard)
                if (target) {
                    let tmp = getMinMax(target, key, defaultObj);
                    return { ...target, ...tmp,isResizable:false, isDraggable:false  };
                } else {
                return {...defaultObj[key].find((card) => card.i === dfCard),isResizable:false, isDraggable:false}
                }
            })
        ])
    );

    // Otherwise, return the object as is
    return final;
}

const actions = {
    getSalesByCustomer: (ruuid, filter, selectedCurrency) => async (dispatch) => {
        dispatch({
            type: GET_SALES_BY_CUSTOMER_REQUEST
        })
        await getSalesByCustomer(ruuid, getFilterString(
            'salesByCategory',
            null,
            filter
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_SALES_BY_CUSTOMER_SUCCESS,
                    payload: response.data.customers
                })
            else handleError(dispatch)(response, GET_SALES_BY_CUSTOMER_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getSalesByCategory: (ruuid, filter, selectedCurrency) => async (dispatch) => {
        dispatch({
            type: GET_SALES_BY_CATEGORY_REQUEST
        })
        await getSalesByCategory(ruuid, getFilterString(
            'salesByCategory',
            null,
            filter
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_SALES_BY_CATEGORY_SUCCESS,
                    payload: response.data.salesByCategory
                })
            else handleError(dispatch)(response, GET_SALES_BY_CATEGORY_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getAvgOrdersYear: (ruuid, selectedBranches, filter, selectedCurrency) => async (dispatch) => {
        dispatch({
            type: GET_AVERAGE_ORDERS_YEAR_REQUEST
        })
        await getAvgOrders(ruuid, getFilterString(
            'avgOrders',
            selectedBranches,
            filter,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            true
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_AVERAGE_ORDERS_YEAR_SUCCESS,
                    payload: response.data.avg
                })
            else handleError(dispatch)(response, GET_AVERAGE_ORDERS_YEAR_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getAvgOrders: (ruuid, selectedBranches, filter, selectedCurrency) => async (dispatch) => {
        dispatch({
            type: GET_AVERAGE_ORDERS_REQUEST
        })
        await getAvgOrders(ruuid, getFilterString(
            'avgOrders',
            selectedBranches,
            filter
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_AVERAGE_ORDERS_SUCCESS,
                    payload: response.data.avg
                })
            else handleError(dispatch)(response, GET_AVERAGE_ORDERS_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getAffiliateClicks: () => async (dispatch) => {
        dispatch({
            type: GET_AFFILIATE_CLICKS_REQUEST
        })
        await getAffiliateClicks().then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_AFFILIATE_CLICKS_SUCCESS,
                    payload: response.data.affiliateLinks
                })
            else handleError(dispatch)(response, GET_AFFILIATE_CLICKS_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getSalesByBranch: (filter, selectedCurrency) => async (dispatch) => {
        dispatch({
            type: GET_SALES_BY_BRANCH_REQUEST
        })
        await getSalesByBranch(getFilterString(
            'salesByBranch',
            null,
            filter,
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_SALES_BY_BRANCH_SUCCESS,
                    payload: response.data.branch
                })
            else handleError(dispatch)(response, GET_SALES_BY_BRANCH_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getSalesByRestaurant: (filter, selectedCurrency) => async (dispatch) => {
        dispatch({
            type: GET_SALES_BY_RESTAURANT_REQUEST
        })
        await getSalesByRestaurant(getFilterString(
            'salesByRestaurant',
            null,
            filter,
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_SALES_BY_RESTAURANT_SUCCESS,
                    payload: response.data.restaurant
                })
            else handleError(dispatch)(response, GET_SALES_BY_RESTAURANT_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getTopItemsBranch: (ruuid, selectedBranches, filter, selectedCurrency) => async (dispatch) => {
        dispatch({
            type: GET_TOP_ITEMS_BRANCH_REQUEST
        })
        await getTopItemsBranch(ruuid, getFilterString(
            'topItemsBranch',
            selectedBranches,
            filter,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            5
        ), selectedCurrency).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_TOP_ITEMS_BRANCH_SUCCESS,
                    payload: response.data.topItemsByBranch
                })
            } else handleError(dispatch)(response, GET_TOP_ITEMS_BRANCH_ERROR, 'GET TOP ITEMS BRANCH ERROR')
        })
    },
    getAvgPrepTime: (ruuid, selectedBranches, filter, selectedCurrency, activeSection) => async (dispatch) => {
        dispatch({
            type: GET_AVERAGE_PREPARATION_TIME_REQUEST
        })
        await getAvgPrepTime(ruuid, getFilterString(
            'avgDelvTime', selectedBranches, filter, activeSection
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_AVERAGE_PREPARATION_TIME_SUCCESS,
                    payload: response.data.avg
                })
            else handleError(dispatch)(response, GET_AVERAGE_PREPARATION_TIME_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getAvgDeliveryTime: (ruuid, selectedBranches, filter, selectedCurrency, activeSection) => async (dispatch) => {
        dispatch({
            type: GET_AVERAGE_DELIVERY_TIME_REQUEST
        })
        await getAvgDeliveryTime(ruuid, getFilterString(
            'avgDelvTime', selectedBranches, filter, activeSection
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_AVERAGE_DELIVERY_TIME_SUCCESS,
                    payload: response.data.avg
                })
            else handleError(dispatch)(response, GET_AVERAGE_DELIVERY_TIME_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getOrdersStatus: (ruuid, selectedBranches, filter, selectedCurrency, activeSection) => async (dispatch) => {
        dispatch({
            type: GET_ORDERS_STATUS_REQUEST
        })
        await getOrdersStatus(ruuid, getFilterString(
            'ordersStatus', selectedBranches, filter
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_ORDERS_STATUS_SUCCESS,
                    payload: response.data.totalOrders
                })
            else handleError(dispatch)(response, GET_ORDERS_STATUS_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getNotSoldItems: (ruuid, selectedBranches, filter, selectedCurrency, activeSection) => async (dispatch) => {
        dispatch({
            type: GET_NOT_SOLD_ITEMS_REQUEST
        })
        await getNotSoldItems(ruuid, getFilterString(
            'notSoldItems', selectedBranches, filter, activeSection
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_NOT_SOLD_ITEMS_SUCCESS,
                    payload: response.data.notSoldItems
                })
            else handleError(dispatch)(response, GET_NOT_SOLD_ITEMS_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getDeliveriesStatus: (ruuid, selectedBranches, filter, selectedCurrency, activeSection) => async (dispatch) => {
        dispatch({
            type: GET_DELIVERIES_STATUS_REQUEST
        })
        await getDeliveryStatus(ruuid, getFilterString(
            'deliveryStatus', selectedBranches, filter, activeSection
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_DELIVERIES_STATUS_SUCCESS,
                    payload: response.data
                })
            else handleError(dispatch)(response, GET_DELIVERIES_STATUS_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getBirthdayCount: (ruuid, filter, selectedCurrency) => async (dispatch) => {
        dispatch({
            type: GET_BIRTHDAY_COUNT_REQUEST
        })
        await getBirthdayCount(ruuid, getFilterString(
            'birthdayCount', [], filter
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_BIRTHDAY_COUNT_SUCCESS,
                    payload: response.data.birthdayCount
                })
            else handleError(dispatch)(response, GET_BIRTHDAY_COUNT_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getNegativeReviews: (ruuid, selectedBranches, filter, selectedCurrency, activeSection, feedbacksSummaryLimit) => async (dispatch) => {
        dispatch({
            type: GET_FEEDBACKS_SUMMARY_NEGATIVE_REQUEST
        })
        await getFeedbacksSummary(ruuid, getFilterString(
            'feedbacksSummary', selectedBranches, filter, activeSection, null, null, null, feedbacksSummaryLimit, null, null, null, null, 'complaint'
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_FEEDBACKS_SUMMARY_NEGATIVE_SUCCESS,
                    payload: response.data.feedbacks
                })
            else handleError(dispatch)(response, GET_FEEDBACKS_SUMMARY_NEGATIVE_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getPositiveReviews: (ruuid, selectedBranches, filter, selectedCurrency, activeSection, feedbacksSummaryLimit) => async (dispatch) => {
        dispatch({
            type: GET_FEEDBACKS_SUMMARY_POSITIVE_REQUEST
        })
        await getFeedbacksSummary(ruuid, getFilterString(
            'feedbacksSummary', selectedBranches, filter, activeSection, null, null, null, feedbacksSummaryLimit, null, null, null, null, 'feedback'
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_FEEDBACKS_SUMMARY_POSITIVE_SUCCESS,
                    payload: response.data.feedbacks
                })
            else handleError(dispatch)(response, GET_FEEDBACKS_SUMMARY_POSITIVE_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getQrCount: (ruuid, selectedBranches, filter, selectedCurrency, activeSection) => async (dispatch) => {
        dispatch({
            type: GET_QR_COUNT_REQUEST
        })
        await getQrCount(ruuid, getFilterString(
            'qrScans', selectedBranches, filter, activeSection
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_QR_COUNT_SUCCESS,
                    payload: response.data.qrCount
                })
            else handleError(dispatch)(response, GET_QR_COUNT_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getTotalOrdersYear: (ruuid, selectedBranches, filter, selectedCurrency, activeSection) => async (dispatch) => {
        dispatch({
            type: GET_TOTAL_ORDERS_YEAR_REQUEST
        })
        await getTotalOrders(ruuid, getFilterString(
            'totalOrders', selectedBranches, filter, activeSection, null, null, null, null, null, null, null, true
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_TOTAL_ORDERS_YEAR_SUCCESS,
                    payload: response.data.orderCounts
                })
            else handleError(dispatch)(response, GET_TOTAL_ORDERS_YEAR_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getNewProduct: (ruuid, filter, selectedCurrency) => async (dispatch) => {
        dispatch({
            type: GET_NEW_PRODUCT_REQUEST
        })
        await getNewProduct(ruuid, getFilterString(
            'newProducts', [], filter
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_NEW_PRODUCT_SUCCESS,
                    payload: response.data.newProductCount
                })
            else handleError(dispatch)(response, GET_NEW_PRODUCT_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getTotalRevenuePerMonth: (ruuid, selectedBranches, years, selectedCurrency, activeSection) => async (dispatch) => {
        dispatch({
            type: GET_TOTAL_REVENUE_MONTH_REQUEST
        })
        await getTotalRevenuePerMonth(ruuid, getFilterString(
            'totalRevenueMonth', selectedBranches, null, activeSection, null, null, null, null, years
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_TOTAL_REVENUE_MONTH_SUCCESS,
                    payload: response.data.revenueByMonthYear
                })
            else handleError(dispatch)(response, GET_TOTAL_REVENUE_MONTH_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getNotActiveCustomers: (ruuid, selectedBranches, filter, selectedCurrency, activeSection) => async (dispatch) => {
        dispatch({
            type: GET_NOT_ACTIVE_CUSTOMERS_REQUEST
        })
        await getNotActiveCustomers(ruuid, getFilterString(
            'notActiveCustomers', selectedBranches, filter, activeSection
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_NOT_ACTIVE_CUSTOMERS_SUCCESS,
                    payload: response.data.notActiveCustomersCount
                })
            else handleError(dispatch)(response, GET_NOT_ACTIVE_CUSTOMERS_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getFeedbacksRating: (ruuid, selectedBranches, filter, selectedCurrency, activeSection) => async (dispatch) => {
        dispatch({
            type: GET_FEEDBACKS_RATING_REQUEST
        })
        await getFeedbacksRating(ruuid, getFilterString(
            'feedbacksRating', selectedBranches, filter, activeSection
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_FEEDBACKS_RATING_SUCCESS,
                    payload: response.data
                })
            else handleError(dispatch)(response, GET_FEEDBACKS_RATING_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getFeedbacksAndComplaints: (ruuid, selectedBranches, filter, selectedCurrency, activeSection, feedbacksAndComplaintsLimit) => async (dispatch) => {
        dispatch({
            type: GET_FEEDBACKS_COMPLAINTS_REQUEST
        })
        await getFeedbacksAndComplaints(ruuid, getFilterString(
            'feedbacksAndComplaints', selectedBranches, filter, activeSection, null, null, feedbacksAndComplaintsLimit, null, null, null, null, null, 'complaint'
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_FEEDBACKS_COMPLAINTS_SUCCESS,
                    payload: response.data.feedbacks
                })
            else handleError(dispatch)(response, GET_FEEDBACKS_COMPLAINTS_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getLeastXItemsAmt: (ruuid, selectedBranches, filter, selectedCurrency, activeSection, topItemsAmtLimit) => async (dispatch) => {
        dispatch({
            type: GET_LEAST_X_ITEMS_AMT_REQUEST
        })
        await getTopXItemsAmt(ruuid, getFilterString(
            'topXItemsAmt', selectedBranches, filter, activeSection, null, topItemsAmtLimit, null, null, null, null, null, null, null, 'DESC'
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_LEAST_X_ITEMS_AMT_SUCCESS,
                    payload: response.data.topItems
                })
            else handleError(dispatch)(response, GET_LEAST_X_ITEMS_AMT_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getTopXItemsAmt: (ruuid, selectedBranches, filter, selectedCurrency, activeSection, topItemsAmtLimit) => async (dispatch) => {
        dispatch({
            type: GET_TOP_X_ITEMS_AMT_REQUEST
        })
        await getTopXItemsAmt(ruuid, getFilterString(
            'topXItemsAmt', selectedBranches, filter, activeSection, null, topItemsAmtLimit, null, null, null, null, null, null, null, 'ASC'
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_TOP_X_ITEMS_AMT_SUCCESS,
                    payload: response.data.topItems
                })
            else handleError(dispatch)(response, GET_TOP_X_ITEMS_AMT_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getLeastXItemsQty: (ruuid, selectedBranches, filter, selectedCurrency, activeSection, topItemsLimit) => async (dispatch) => {
        dispatch({
            type: GET_LEAST_X_ITEMS_QTY_REQUEST
        })
        await getTopXItemsQty(ruuid, getFilterString(
            'topXItems', selectedBranches, filter, activeSection, topItemsLimit, null, null, null, null, null, null, null, null, 'ASC'
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_LEAST_X_ITEMS_QTY_SUCCESS,
                    payload: response.data.topItems
                })
            else handleError(dispatch)(response, GET_LEAST_X_ITEMS_QTY_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getTopXItemsQty: (ruuid, selectedBranches, filter, selectedCurrency, activeSection, topItemsLimit) => async (dispatch) => {
        dispatch({
            type: GET_TOP_X_ITEMS_QTY_REQUEST
        })
        await getTopXItemsQty(ruuid, getFilterString(
            'topXItems', selectedBranches, filter, activeSection, topItemsLimit, null, null, null, null, null, null, null, null, 'DESC'
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_TOP_X_ITEMS_QTY_SUCCESS,
                    payload: response.data.topItems
                })
            else handleError(dispatch)(response, GET_TOP_X_ITEMS_QTY_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getTotalPromoDiscounts: (ruuid, selectedBranches, filter, selectedCurrency) => async (dispatch) => {
        dispatch({
            type: GET_TOTAL_PROMO_REQUEST
        })
        await getTotalPromoDiscounts(ruuid, getFilterString(
            'totalPromoDiscounts', selectedBranches, filter
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_TOTAL_PROMO_SUCCESS,
                    payload: response.data.discounts
                })
            else handleError(dispatch)(response, GET_TOTAL_PROMO_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getTotalDiscounts: (ruuid, selectedBranches, filter, activeSection, selectedCurrency) => async (dispatch) => {
        dispatch({
            type: GET_TOTAL_DISCOUNTS_REQUEST
        })
        await getTotalDiscounts(ruuid, getFilterString(
            'totalDiscounts', selectedBranches, filter, activeSection
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_TOTAL_DISCOUNTS_SUCCESS,
                    payload: response.data.discounts
                })
            else handleError(dispatch)(response, GET_TOTAL_DISCOUNTS_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getSignupsByAffiliate: () => async (dispatch) => {
        dispatch({
            type: GET_SIGNUPS_BY_AFFILIATE_REQUEST
        })
        await getSignupsByAffiliate().then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_SIGNUPS_BY_AFFILIATE_SUCCESS,
                    payload: response.data.affiliateLinkSignUpsSummary
                })
            else handleError(dispatch)(response, GET_SIGNUPS_BY_AFFILIATE_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getSalesByAffiliate: () => async (dispatch) => {
        dispatch({
            type: GET_SALES_BY_AFFILIATE_REQUEST
        })
        await getSalesByAffiliate().then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_SALES_BY_AFFILIATE_SUCCESS,
                    payload: response.data.affiliateLinkSalesSummary
                })
            else handleError(dispatch)(response, GET_SALES_BY_AFFILIATE_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getSalesByZone: (ruuid, selectedBranches, filter, activeSection, selectedCurrency) => async (dispatch) => {
        dispatch({
            type: GET_SALES_BY_ZONE_REQUEST
        })
        await getSalesByZone(ruuid, getFilterString(
            'salesByZone', selectedBranches, filter, activeSection
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_SALES_BY_ZONE_SUCCESS,
                    payload: response.data
                })
            else handleError(dispatch)(response, GET_SALES_BY_ZONE_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getCanceledOrders: (ruuid, selectedBranches, filter, activeSection, selectedCurrency) => async (dispatch) => {
        dispatch({
            type: GET_CANCELED_ORDERS_REQUEST
        })
        await getCanceledOrders(ruuid, getFilterString(
            'canceledOrders', selectedBranches, filter, activeSection
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_CANCELED_ORDERS_SUCCESS,
                    payload: response.data.canceledOrders
                })
            else handleError(dispatch)(response, GET_CANCELED_ORDERS_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getNewCustomers: (filter, activeSection, selectedCurrency) => async (dispatch) => {
        dispatch({
            type: GET_NEW_CUSTOMERS_REQUEST
        })
        await getNewCustomers(null, getFilterString(
            'newCustomers', null, filter, activeSection
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_NEW_CUSTOMERS_SUCCESS,
                    payload: response.data
                })
            else handleError(dispatch)(response, GET_NEW_CUSTOMERS_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getTotalOrders: (ruuid, selectedBranches, filter, activeSection, selectedCurrency) => async (dispatch) => {
        dispatch({
            type: GET_TOTAL_ORDERS_REQUEST
        })
        await getTotalOrders(ruuid, getFilterString(
            'totalOrders', selectedBranches, filter, activeSection
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_TOTAL_ORDERS_SUCCESS,
                    payload: response.data.orderCounts
                })
            else handleError(dispatch)(response, GET_TOTAL_ORDERS_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getLateOrders: (ruuid, selectedBranches, filter, activeSection, selectedCurrency) => async (dispatch) => {
        dispatch({
            type: GET_LATE_ORDERS_REQUEST
        })
        await getLateOrders(ruuid, getFilterString(
            'lateOrders',
            selectedBranches,
            filter,
            activeSection,
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_LATE_ORDERS_SUCCESS,
                    payload: response.data.lateOrders
                })
            else handleError(dispatch)(response, GET_LATE_ORDERS_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getAvgOrderPricePeriod: (ruuid, selectedBranches, filter, activeSection, selectedCurrency) => async (dispatch) => {
        dispatch({
            type: GET_AVERAGE_ORDER_PRICE_PERIOD_REQUEST
        })
        await getAvgOrderPrice(ruuid, getFilterString(
            'avgOrderPrice', selectedBranches, filter, activeSection
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_AVERAGE_ORDER_PRICE_PERIOD_SUCCESS,
                    payload: response.data.avgBilledPrice
                })
            else handleError(dispatch)(response, GET_AVERAGE_ORDER_PRICE_PERIOD_ERROR, 'GET AVERAGE ORDER PRICE PERIOD ERROR')
        })
    },
    getAvgOrderPrice: (ruuid, selectedBranches, filter, activeSection, selectedCurrency) => async (dispatch) => {
        dispatch({
            type: GET_AVERAGE_ORDER_PRICE_REQUEST
        })
        await getAvgOrderPrice(ruuid, getFilterString(
            'avgOrderPrice', selectedBranches, filter, activeSection, null, null, null, null, null, null, null, true
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_AVERAGE_ORDER_PRICE_SUCCESS,
                    payload: response.data.avgBilledPrice
                })
            else handleError(dispatch)(response, GET_AVERAGE_ORDER_PRICE_ERROR, 'GET AVERAGE ORDER PRICE ERROR')
        })
    },
    getTopSpenders: (ruuid, selectedBranches, filter, activeSection, selectedCurrency) => async (dispatch) => {
        dispatch({
            type: GET_TOP_SPENDERS_REQUEST
        })
        await getTopSpenders(ruuid, getFilterString(
            'topSpenders', selectedBranches, filter, activeSection
        ), selectedCurrency).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_TOP_SPENDERS_SUCCESS,
                    payload: response.data.topSpenders
                })
            else handleError(dispatch)(response, GET_TOP_SPENDERS_ERROR, 'GET TOP SPENDERS ERROR')
        })
    },
    getCardsPositioning: () => async (dispatch) => {
        dispatch({
            type: GET_CARDS_POSITIONING_REQUEST
        })
        await getCardsPositioning().then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: SET_CUSTOMERS_LAYOUT,
                    payload: checkArrays(response.data.dashboardData.customersCards, response.data.default.customersCards)
                })
                dispatch({
                    type: SET_PRODUCT_LAYOUT,
                    payload: checkArrays(response.data.dashboardData.productCards, response.data.default.productCards)
                })
                dispatch({
                    type: SET_TODAY_LAYOUT,
                    payload: checkArrays(response.data.dashboardData.todayCards, response.data.default.todayCards)
                })
                dispatch({
                    type: SET_TRANSACTIONS_LAYOUT,
                    payload: checkArrays(response.data.dashboardData.transactionCards, response.data.default.transactionCards)
                })
                dispatch({
                    type: GET_CARDS_POSITIONING_SUCCESS
                })
            } else handleError(dispatch)(response, GET_CARDS_POSITIONING_ERROR, 'GET CARDS POSITIONING ERROR')
        })
    },
    setCardsPositioning: (data,setIsEditing) => async (dispatch) => {
        try {
            dispatch({
                type: SET_CARDS_POSITIONING_STARTED
            });

            const response = await setCardsPositioning(data); // Wait for setCardsPositioning to complete

            if (response.status === 200) {
                toast.success(response.data.message || 'DASHBOARD SAVED');
                setIsEditing(false)
                dispatch({
                    type: GET_CARDS_POSITIONING_REQUEST
                });

                const getResponse = await getCardsPositioning(); // Wait for getCardsPositioning to complete

                if (getResponse.status === 200) {
                    dispatch({
                        type: SET_CUSTOMERS_LAYOUT,
                        payload: checkArrays(getResponse.data.dashboardData.customersCards, getResponse.data.default.customersCards)
                    })
                    dispatch({
                        type: SET_PRODUCT_LAYOUT,
                        payload: checkArrays(getResponse.data.dashboardData.productCards, getResponse.data.default.productCards)
                    })
                    dispatch({
                        type: SET_TODAY_LAYOUT,
                        payload: checkArrays(getResponse.data.dashboardData.todayCards, getResponse.data.default.todayCards)
                    })
                    dispatch({
                        type: SET_TRANSACTIONS_LAYOUT,
                        payload: checkArrays(getResponse.data.dashboardData.transactionCards, getResponse.data.default.transactionCards)
                    })
                    dispatch({
                        type: GET_CARDS_POSITIONING_SUCCESS
                    })
                } else {
                    handleError(dispatch)(getResponse, GET_CARDS_POSITIONING_ERROR, 'GET CARDS POSITIONING ERROR');
                }
            } else {
                handleError(dispatch)(response, null, 'SET DASHBOARD ERROR');
            }
        } catch (error) {
            console.log(error)
            //handleError(dispatch)(error, SET_CARDS_POSITIONING_ENDED, 'An unexpected error occurred');
        } finally {
            dispatch({
                type: SET_CARDS_POSITIONING_ENDED
            });
        }
    },
    setConsolidatedCurrency: (data, onClose) => async (dispatch) => {
        dispatch({
            type: SET_CONSOLIDATED_CURRENCY_STARTED
        })
        await setConsolidatedCurrency(data).then((response) => {
            if (response.status === 200) {
                toast.success('UPDATE CONSOLIDATED CURRENCY SUCCESS')
                dispatch({
                    type: GET_CONSOLIDATED_CURRENCY_SUCCESS,
                    payload: response.data.message
                })
                onClose()
            } else handleError(dispatch)(response, null, 'UPDATE CONSOLIDATED CURRENCY ERROR')
        })
    },
    getConsolidatedCurrency: () => async (dispatch) => {
        dispatch({
            type: GET_CONSOLIDATED_CURRENCY_REQUEST
        })
        await getConsolidatedCurrency().then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_CONSOLIDATED_CURRENCY_SUCCESS,
                    payload: response.data.message
                })
            else handleError(dispatch)(response, GET_CONSOLIDATED_CURRENCY_ERROR, 'GET CONSOLIDATED CURRENCY ERROR')
        })
    },
    getRestaurantsCurrency: () => async (dispatch) => {
        dispatch({
            type: GET_RESTAURANTS_CURRENCY_REQUEST
        })
        await getRestaurantsCurrency().then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_RESTAURANTS_CURRENCY_SUCCESS,
                    payload: response.data.currencies
                })
            else handleError(dispatch)(response, GET_RESTAURANTS_CURRENCY_ERROR, 'GET RESTAURANTS CURRENCY ERROR')
        })
    },
    setDashboardRest: (ruuid) => (dispatch) => {
        dispatch({
            type: SET_DASHBOARD_REST,
            payload: ruuid
        })
    },
    getNewProductsList: (ruuid, filterString) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_NEW_PRODUCTS_REQUEST
        })
        await getNewProductsList(ruuid, filterString).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_DASHBOARD_NEW_PRODUCTS_SUCCESS,
                    payload: response.data.newProducts
                })
            else handleError(dispatch)(response, GET_DASHBOARD_NEW_PRODUCTS_ERROR, 'GET DASHBOARD NEW PRODUCTS ERROR')
        })
    },
    getOrdersCountGreater: (ruuid, filterString, ordersBreakPoint) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_ORDERS_COUNT_REQUEST
        })
        await getCustomerCountOrder(ruuid, filterString += ordersBreakPoint).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_DASHBOARD_ORDERS_COUNT_SUCCESS,
                    payload: response.data.customersCount
                })
            else handleError(dispatch)(response, GET_DASHBOARD_ORDERS_COUNT_ERROR, 'GET DASHBOARD ORDERS COUNT ERROR')
        })
    },
    getOrdersValue: (ruuid, filterString, valueBreakpoint) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_ORDERS_VALUE_REQUEST
        })
        await getCustomerCountValue(ruuid, filterString += valueBreakpoint).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_DASHBOARD_ORDERS_VALUE_SUCCESS,
                    payload: response.data.customersCount
                })
            else handleError(dispatch)(response, GET_DASHBOARD_ORDERS_VALUE_ERROR, 'GET DASHBOARD ORDERS VALUE ERROR')
        })
    },
    getDashboardTotalRevenueTransactions: (ruuid, filterString) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_REQUEST
        })
        await getTotalRevenuePerMonth(ruuid, filterString).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_SUCCESS,
                    payload: response.data.revenueByMonthYear
                })
            } else handleError(dispatch)(response, GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_ERROR, 'GET  TOTAL NRVEENUE ERROR')
        })
    },
    getDashboardTotalRevenue: (ruuid, filterString) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_TOTAL_REVENUE_REQUEST
        })
        await getTotalRevenuePerMonth(ruuid, filterString).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_DASHBOARD_TOTAL_REVENUE_SUCCESS,
                    payload: response.data.revenueByMonthYear
                })
            } else handleError(dispatch)(response, GET_DASHBOARD_TOTAL_REVENUE_ERROR, 'GET TOTAL REVENUE ERROR')
        })
    },
}
export default actions