import { login, forgotPass, getMeProfile, otp, verifyOTP } from '../../config/config'
import parseError from '../../utils/parseError'
import { toast } from 'react-toastify'
import globalActions from './GlobalActions'
import { handleError } from '../../utils/utils'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const SET_WRONG_PASS_FALSE = 'SET_WRONG_PASS_FALSE'
export const SET_WRONG_PASS_TRUE = 'SET_WRONG_PASS_TRUE'

export const FORGOT_REQUEST = 'FORGOT_REQUEST'
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS'
export const FORGOT_ERROR = 'FORGOT_ERROR'

export const GETME_REQUEST = 'GETME_REQUEST'
export const GETME_SUCCESS = 'GETME_SUCCESS'
export const GETME_ERROR = 'GETME_ERROR'

export const EDITME_REQUEST = 'EDITME_REQUEST'
export const EDITME_SUCCESS = 'EDITME_SUCCESS'
export const EDITME_ERROR = 'EDITME_ERROR'

export const LOGOUT_USER = 'LOGOUT_USER'
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES'
export const SET_PERMISSIONS = 'SET_PERMISSIONS'
export const CLEAR_AUTH_LOADING = 'CLEAR_AUTH_LOADING'

export const VERIFY_OTP_STARTED = 'VERIFY_OTP_STARTED'
export const VERIFY_OTP_ENDED = 'VERIFY_OTP_ENDED'

const actions = {
  verifyOTP: (data, onClose, login) => async (dispatch) => {
    dispatch({
      type: VERIFY_OTP_STARTED
    })
    await verifyOTP(data).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message || 'VERIFIED SUCCESSFULLY')
        onClose()
        login({
          email: data.email,
          password: data.password
        })
      } else handleError(dispatch)(response, null, 'VERIFICATION ERROR')
    })
    dispatch({
      type: VERIFY_OTP_ENDED
    })
  },
  // LOGIN USER
  login: (data, history, language, editUserSettings, setVerifyModalState) => async (dispatch) => {
    dispatch({
      type: LOGIN_REQUEST,
    });
    dispatch({
      type: SET_WRONG_PASS_FALSE,
    });
    await login(data).then((res) => {
      if (res?.status === 200) {
        if (editUserSettings) {
          let final = {
            settings: {
              language: language
            }
          }
          editUserSettings(final)
        }
        // Successful login handling
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { accessToken: res?.data?.access_token }
        });
        localStorage.setItem('access_token', res?.data?.access_token);
        history.push('/dashboard');
      } else if (res?.status === 422) {
        // Wrong password scenario (422)
        dispatch({
          type: LOGIN_ERROR,
        });
        dispatch({
          type: SET_WRONG_PASS_TRUE,
        });
      } else {
        // Handle other cases
        handleError(dispatch)(res, LOGIN_ERROR, 'LOGIN ERROR');
        if (res?.status === 401) {
          setVerifyModalState(true);
          otp({ email: data.email });
        }
      }
    });
    
  },

  // FORGOT PASS USER
  forgotPass: (uuid, data) => async (dispatch) => {
    dispatch({
      type: FORGOT_REQUEST,
    })
    await forgotPass(uuid, data).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: FORGOT_SUCCESS,
        })
      } else {
        handleError(dispatch)(res, FORGOT_ERROR, 'FORGOT ERROR')
        // toast.error(res?.data?.message || 'RESET PASSWORD ERROR')
        // dispatch({
        //   type: FORGOT_ERROR,
        // })
      }
    })
  },

  logout: () => (dispatch) => {
    dispatch({
      type: LOGOUT_USER,
    })
    dispatch(globalActions.clearAllData())
  },

  clearWrongPass:()=>async (dispatch) =>(
    dispatch({
      type: SET_WRONG_PASS_FALSE
    })
  ),

  // GET ME INFO
  getMe: () => async (dispatch) => {
    dispatch({
      type: GETME_REQUEST,
    })
    await getMeProfile().then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GETME_SUCCESS,
          payload: res?.data?.adminUser,
        })
        // dispatch({
        //   type: SET_PERMISSIONS,
        //   payload: res?.data?.permissions
        // })
      } else {
        handleError(dispatch)(res, GETME_ERROR, 'GET ME ERROR')
      }
    })
  },

  // EDIT ME INFO
  editMe: (data) => async (dispatch) => {
    dispatch({
      type: EDITME_REQUEST,
    })
    await getMeProfile(data).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: EDITME_SUCCESS,
          payload: {
            me: res?.data?.data,
          },
        })
      } else {
        handleError(dispatch)(res, EDITME_ERROR, 'EDIT ME ERROR')
        // toast.error(res?.data?.message || 'Edit admin info error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // dispatch({
        //   type: EDITME_ERROR,
        //   payload: {
        //     message: parseError(res?.data?.error) || res?.data?.message || 'Edit admin info error',
        //   },
        // })
      }
    })
  },

  // CLEAR MESSAGES
  clearMessage: () => (dispatch) => {
    dispatch({
      type: CLEAR_MESSAGES,
    })
  },
}

export default actions
