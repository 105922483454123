export const CLEAR_LAYOUT = 'CLEAR_LAYOUT'
export const SET_VIEW = 'SET_VIEW'

const actions = {
  setView: (state) => (dispatch) => {
    dispatch({
      type: SET_VIEW,
      payload: state
    })
  },
  clearLayout: () => (dispatch) => {
    dispatch({
      type: CLEAR_LAYOUT
    })
  },
}

export default actions