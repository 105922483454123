import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SET_WRONG_PASS_FALSE,
  SET_WRONG_PASS_TRUE,
  FORGOT_REQUEST,
  FORGOT_SUCCESS,
  FORGOT_ERROR,
  LOGOUT_USER,
  CLEAR_MESSAGES,
  GETME_REQUEST,
  GETME_SUCCESS,
  GETME_ERROR,
  EDITME_REQUEST,
  EDITME_SUCCESS,
  EDITME_ERROR,
  SET_PERMISSIONS,
  CLEAR_AUTH_LOADING,
  VERIFY_OTP_STARTED,
  VERIFY_OTP_ENDED
} from '../actions/AuthActions'

const initialState = {
  accessToken: '',
  admin: '',
  loadingMe: '',
  loginLoading: false,
  loginMessage: ['', ''], // [0] error / [1] success
  forgotLoading: false,
  permissions: [],
  otpLoading: false,
  wrongPass:false,
}

function auth(state = initialState, action) {
  switch (action.type) {
    case SET_WRONG_PASS_TRUE:
      return Object.assign({}, state,{
        wrongPass: true,
      })
    case SET_WRONG_PASS_FALSE:
      return Object.assign({}, state,{
        wrongPass: false,
      })
    case VERIFY_OTP_ENDED:
      return Object.assign({}, state, {
        otpLoading: false
      })
    case VERIFY_OTP_STARTED:
      return Object.assign({}, state, {
        otpLoading: true
      })
    case CLEAR_AUTH_LOADING:
      return Object.assign({
        loadingMe: '',
        loginLoading: false,
        forgotLoading: false,
        otpLoading: false
      })
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        accessToken: '',
        loginLoading: true,
        loginMessage: ['', ''],
      })
    case SET_PERMISSIONS: {
      return Object.assign({}, state, {
        permissions: action.payload
      })
    }
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        accessToken: action.payload.accessToken,
        loginLoading: false,
        loginMessage: ['', action.payload.message],
        wrongPass: false,
      })
    case LOGIN_ERROR:
      return Object.assign({}, state, {
        accessToken: '',
        loginLoading: false,
        // loginMessage: [action.payload.message, ''],
      })

    case FORGOT_REQUEST:
      return Object.assign({}, state, {
        forgotLoading: true,
      })
    case FORGOT_SUCCESS:
      return Object.assign({}, state, {
        forgotLoading: false,
      })
    case FORGOT_ERROR:
      return Object.assign({}, state, {
        forgotLoading: false,
      })

    case LOGOUT_USER:
      localStorage.removeItem('access_token')
      return Object.assign({}, state, {
        accessToken: '',
      })

    case GETME_REQUEST:
      return Object.assign({}, state, {
        loadingMe: true,
      })
    case GETME_SUCCESS:
      return Object.assign({}, state, {
        loadingMe: false,
        admin: action.payload,
      })
    case GETME_ERROR:
      return Object.assign({}, state, {
        loadingMe: false,
        admin: {},
      })

    case EDITME_REQUEST:
      return Object.assign({}, state, {
        loadingMe: true,
      })
    case EDITME_SUCCESS:
      return Object.assign({}, state, {
        loadingMe: false,
        admin: action.payload.me,
      })
    case EDITME_ERROR:
      return Object.assign({}, state, {
        loadingMe: false,
      })

    case CLEAR_MESSAGES:
      return Object.assign({}, state, {
        loginMessage: ['', ''],
        forgotMessage: ['', ''],
        loginLoading: false,
        forgotLoading: false,
      })

    default:
      return state
  }
}

export default auth
