import { GET_TRANSLATION_REQUEST, GET_TRANSLATION_SUCCESS, GET_TRANSLATION_ERROR, SET_LANGUAGE, CLEAR_ALL_TRANSLATIONS, CLEAR_TRANSLATIONS_LOADING } from '../actions/TranslationsActions'

const initialState = {
  language: '',
  loading: false,
  translation: [],
  supportedLanguages: [],
}

function translation(state = initialState, action) {
  switch (action.type) {
    case CLEAR_TRANSLATIONS_LOADING:
      return Object.assign({}, state, {
        loading: false
      })
    case GET_TRANSLATION_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      })
    case GET_TRANSLATION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        translation: action.payload.translations,
        supportedLanguages: action.payload.supportedLanguages,
      })
    case GET_TRANSLATION_ERROR:
      return Object.assign({}, state, {
        loading: false,
      })
    case SET_LANGUAGE:
      return Object.assign({}, state, {
        language: action.payload
      })
    case CLEAR_ALL_TRANSLATIONS:
      return initialState
    default:
      return state
  }
}

export default translation
